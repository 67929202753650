import React, { useEffect, useState } from "react";
import Sidebarmenu from "../../Sidebar/Sidebarmenu";
import Header from "../Header/Header";
import Select from "react-select";
import "../Dashboard.css";
import remove from "../../../img/remove.png";
import img_upload from "../../../img/vendor.png";
import { useDispatch } from "react-redux";
import axios from "axios";
import Modal from "react-modal";
import loadergif from "../../../img/landing/loader123.gif";
import {
  createVendor,
  deleteVendor,
  getCityList,
  getVendorList,
  updateVendor,
  api_url,
  feed_back,
} from "../../../Redux/Reducer/AuthReducer";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: "AKIAU3H7RLHX3WKU6DUS",
  secretAccessKey: "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",
  region: "us-east-1",
});

const s3 = new AWS.S3();
const bucketName = "fmb-images";
export default function Vendors(props) {
  const user_Role = localStorage.getItem("access_Role");
  const imgurl = "https://fmb-images.s3.amazonaws.com/images/";
  var token = localStorage.getItem("access_token");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [vendor, setVendor] = useState("");
  const [pincode, setPincode] = useState("");
  const [zone, setZone] = useState("");
  const [city, setCity] = useState("");
  const [totalcount, setTotalCount] = useState("");
  const [updateImage, setUpdateImage] = useState("");
  const [item, setItem] = useState([]);
  const [previewImage, setPreviewImage] = useState(img_upload);
  const [RemoveIsOpen, setIsRemoveOpen] = React.useState(false);
  const [image, setImage] = useState();
  const [id, setId] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [GetCountry, setGetCountry] = useState("");
  const [sortColumn, setSortColumn] = useState("created_at");
  const [sortOrder, setSortOrder] = useState("asc");
  const [getvendor, setGetVendor] = useState("");
  const [validationerror, setValidationerror] = useState(false);

  const Statelist = [
    { value: "1", label: "California" },
    { value: "2", label: "Texas" },
    { value: "3", label: "Colorado" },
    { value: "4", label: "Hawaii" },
    { value: "5", label: "Illinois" },
  ];

  const Citylist = [
    { value: "1", label: "Houston" },
    { value: "2", label: "San Antonio" },
    { value: "3", label: "Dallas" },
    { value: "4", label: "Austin" },
    { value: "5", label: "Fort Worth" },
    { value: "6", label: "El Paso" },
    { value: "7", label: "Arlington" },
    { value: "8", label: "Corpus Christi" },
    { value: "9", label: "Plano" },
    { value: "10", label: "Laredo" },
    { value: "11", label: "Lubbock" },
    { value: "12", label: "Garland" },
    { value: "13", label: "Irving" },
    { value: "14", label: "Amarillo" },
    { value: "15", label: "Grand Prairie" },
    { value: "16", label: "Brownsville" },
    { value: "17", label: "Pasadena" },
    { value: "18", label: "McKinney" },
    { value: "19", label: "Mesquite" },
    { value: "20", label: "McAllen" },
    { value: "21", label: "Killeen" },
    { value: "22", label: "Frisco" },
    { value: "23", label: "Waco" },
    { value: "24", label: "Carrollton" },
    { value: "25", label: "Denton" },
    { value: "26", label: "Midland" },
    { value: "27", label: "Abilene" },
    { value: "28", label: "Beaumont" },
    { value: "29", label: "Round Rock" },
    { value: "30", label: "Odessa" },
    { value: "31", label: "Wichita Falls" },
    { value: "32", label: "Richardson" },
    { value: "33", label: "Lewisville" },
    { value: "34", label: "Tyler" },
    { value: "35", label: "College Station" },
    { value: "36", label: "Pearland" },
    { value: "37", label: "San Angelo" },
    { value: "38", label: "Allen" },
    { value: "39", label: "League Cit" },
    { value: "40", label: "Sugar Land" },
    { value: "41", label: "Longview" },
    { value: "42", label: "Edinburg" },
    { value: "43", label: "Mission" },
    { value: "44", label: "Pharr" },
    { value: "45", label: "Baytown" },
    { value: "46", label: "Temple" },
    { value: "47", label: "Missouri City" },
    { value: "48", label: "Flower Mound" },
    { value: "49", label: "Harlingen" },
    { value: "50", label: "North Richland Hills" },
  ];

  const handleSort = (columnName) => {
    if (sortColumn === columnName) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      setSortColumn(columnName);
      setSortOrder("asc");
    }
  };

  const getSortIcon = (columnName) => {
    if (sortColumn === columnName) {
      return sortOrder === "asc" ? (
        <i className="fa fa-sort-up"></i>
      ) : (
        <i className="fa fa-sort-down"></i>
      );
    }
    return null;
  };

  const sortvendor = [...item].sort((a, b) => {
    if (sortColumn === "vendername") {
      const nameA = a.vendor_name.toLowerCase();
      const nameB = b.vendor_name.toLowerCase();

      if (nameA < nameB) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (nameA > nameB) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    }
    // Handle sorting for other columns if needed
    return 0;
  });

  function RemoveModal() {
    setIsRemoveOpen(true);
  }
  function RemovecloseModal() {
    setIsRemoveOpen(false);
  }

  function RemoveOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  const customModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  // const handleChangeImg = (event) => {
  //   setUpdateImage(event.target.files[0]);
  //   setPreviewImage(URL.createObjectURL(event.target.files[0]));
  // };
  const allowedFileTypes = ["jpg", "jpeg", "png", "gif"];
  const handleChangeImg = (e) => {
    const file = e.target.files[0];
    const fileNameParts = file.name.split(".");
    const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();

    if (!allowedFileTypes.includes(fileExtension)) {
      console.error(
        "Invalid file type. Allowed types are:",
        allowedFileTypes.join(", ")
      );

      toast.error(
        "Invalid file type. Allowed types are:",
        allowedFileTypes.join(", ")
      );
      return;
    }

    const params = {
      Bucket: bucketName,
      Key: `images/${file.name}`,
      Body: file,
      ACL: "public-read",
    };

    s3.upload(params, (err, data) => {
      if (err) {
        console.error("Error uploading image to S3:", err);
      } else {
        console.log("Image uploaded successfully:", data.Location);
        // Set the image URL in your component's state for display

        const parts = data.Location.split("/");
        const fileName = decodeURIComponent(parts[parts.length - 1]);
        const previewURL = URL.createObjectURL(file);
        console.log(fileName);
        setUpdateImage(fileName);
        // Set the preview image URL in your component's state
        setPreviewImage(previewURL);
      }
    });
  };

  const hanleupdateurl = (event) => {
    const file = event.target.files[0];

    const fileNameParts = file.name.split(".");
    const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();

    if (!allowedFileTypes.includes(fileExtension)) {
      console.error(
        "Invalid file type. Allowed types are:",
        allowedFileTypes.join(", ")
      );

      toast.error(
        "Invalid file type. Allowed types are:",
        allowedFileTypes.join(", ")
      );
      return;
    }

    const params = {
      Bucket: bucketName,
      Key: `images/${file.name}`,
      Body: file,
      ACL: "public-read",
    };

    s3.upload(params, (err, data) => {
      if (err) {
        console.error("Error uploading image to S3:", err);
      } else {
        console.log("Image uploaded successfully:", data.Location);
        // Set the image URL in your component's state for display

        const parts = data.Location.split("/");
        const fileName = decodeURIComponent(parts[parts.length - 1]);
        const previewURL = URL.createObjectURL(file);
        console.log(fileName);
        setUpdateImage(fileName);
        // Set the preview image URL in your component's state
        setImage(previewURL);
      }
    });
  };

  const getVendorData = () => {
    setLoading(true);
    dispatch(getVendorList())
      .then((res) => {
        if (res?.payload?.status == true) {
          setItem(res?.payload?.data);
          setTotalCount(res?.payload?.count);
          props.data(item);
        } else {
          toast.error(res?.payload?.message);
        }
      })
      .finally(() => {
        setLoading(false); // Set loading to false when data fetching is complete
      });
  };

  useEffect(() => {
    getVendorData();
  }, []);

  const access_id = localStorage.getItem("access_id");
  const mobilenumberregex = /^\d{10}$/;
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!vendor) {
      setValidationerror(true);
      return false;
    } else {
      const postData = {
        file: updateImage,
        contact_person: name,
        contact_phone: number,
        zip_code: pincode,
        city: selectedCity?.label,
        state: selectedState?.label,
        vendor_name: vendor,
        notes: getvendor,
        updated_user: access_id,
        country: "US",
      };
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },
      };
      axios
        .post(feed_back + "admin/vendor-create", postData, config, {})
        .then((res) => {
          if (res?.data?.status == true) {
            toast.success(res?.data?.message);
            // getVendorData();
            setName("");
            setNumber("");
            setVendor("");
            setPincode("");
            getVendorData();
            setUpdateImage("");

            const modal_close = document
              .getElementById("modal_close_btn")
              .click();
            // const modal_close = document.getElementById("modal_close_btn").click();
            setValidationerror(false);
          } else {
            toast.error(res?.data?.message);
          }
        });
      getVendorData();
    }
  };

  const updatehandleSubmit = (e) => {
    e.preventDefault();

    if (!vendor) {
      setValidationerror(true);
      return false;
    } else {
      const postData = {
        file: updateImage,
        contact_person: name,
        contact_phone: number,
        zip_code: pincode,
        city: selectedCity,
        state: selectedState,
        vendor_name: vendor,
        notes: getvendor,
        updated_user: access_id,
        country: "US",
      };

      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },
      };
      axios
        .put(feed_back + `admin/vendor-update/${id}`, postData, config, {})
        .then((res) => {
          if (res?.data?.status == true) {
            toast.success(res?.payload?.message, {
              autoClose: 5000,
            });

            setVendor("");
            setName("");
            setNumber("");
            setPincode("");
            setCity();
            setZone("");
            getVendorData();

            const modal_close2 = document
              .getElementById("modal_close_btn2")
              .click();

            setValidationerror(false);
          } else {
            toast.error(res?.data?.message);
          }
        });
      getVendorData();
    }
  };
  const dataget = (items) => {
    setVendor(items.vendor_name);
    setName(items.contact_person);
    setNumber(items.contact_phone);
    setPincode(items.zip_code);
    setSelectedCity(items.city);
    setSelectedState(items.state);
    setImage(items.vendor_logo ? imgurl + items.vendor_logo : previewImage);
    setId(items.id);
    setGetVendor(items.notes);
    setGetCountry(items.country);
    console.log("items.state", items);
  };

  const deleteVendorData = (id) => {
    dispatch(deleteVendor(id)).then((res) => {
      if (res?.payload?.status == true) {
        toast.success(res?.payload?.message);
        getVendorData();
        RemovecloseModal();
      } else {
        toast.error(res?.payload?.message);
      }
    });
    getVendorData();
    setName("");
    setNumber("");
    setVendor("");
    setPincode("");
  };

  const reset = () => {
    // setSrc(img_upload);
    setName("");
    setNumber("");
    setVendor("");
    setPincode("");
    setCity("");
    setZone("");

    setUpdateImage(img_upload);
  };
  const fileName = "VendorExelSheet";

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = async () => {
    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      };
      const response = await fetch(
        feed_back +
          `admin/search-vendor?vendor_name=${encodeURIComponent(searchQuery)}`,
        {
          method: "GET",
          headers: headers,
        }
      );
      if (response.ok) {
        const data = await response.json();
        console.log("data:", data); // Check the structure of the received data
        if (data.data && Array.isArray(data.data)) {
          setItem(data.data);
          // Set the total number of pages
          console.log("Invali:", data.data);
        } else {
          console.log("Invalid data format:", data.data);
          setItem(data);
        }
      } else {
        console.log("Error: ", response.status);
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    if (searchQuery) {
      handleSearch();
    } else {
      getVendorData();
    }
  }, [searchQuery]);

  const [totalPages, setTotalPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const handleFirstPage = () => {
    setCurrentPage(1);
    fetchData(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    fetchData(totalPages);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 10; // Maximum number of visible pages

    const startPage = Math.max(
      1,
      currentPage - Math.floor(maxVisiblePages / 2)
    );
    const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };
  const fetchData = async (page) => {
    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      };

      // Fetch data for the specified page
      const response = await fetch(
        feed_back + `admin/vendor-get?page=${page}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      if (response.ok) {
        const responseData = await response.json();

        if (
          responseData &&
          responseData.data &&
          Array.isArray(responseData.data.dish) &&
          responseData.totalPages
        ) {
          const { vender, totalPages } = responseData.data;

          // Set the fetched data and total pages
          setItem(vender);
          setTotalPages(totalPages);
        } else {
          setItem(responseData.data);
          setTotalPages(responseData.totalPages);
        }
      } else {
        console.log("Error fetching data:", response.status);
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage); // Fetch data for the initial page
  }, []);

  const csvData = sortvendor.map((items) => {
    return {
      vendorname: items.vendor_name,
      contactperson: items.contact_person,
      contactphone: items.contact_phone,
      state: items.state,
      city: items.city,
      zipcode: items.zip_code,
      VendorNotes: items.notes,
    };
  });

  const csvHeaders = [
    { label: "Vendor Name", key: "vendorname" },
    { label: "Contact Person", key: "contactperson" },
    { label: "Contact Phone", key: "contactphone" },
    { label: "State", key: "state" },
    { label: "City", key: "city" },
    { label: "Zip Code", key: "zipcode" },
    { label: "Vendor Notes", key: "VendorNotes" },
  ];

  const venderlist = totalcount ? totalcount : "";
  return (
    <div>
      <Header />
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarmenu />
        <section className="main_section">
          <div className="custam">
            <div className="main_section2">
              <div className="ExportInventory thali_section Thali_page">
                <div className="container-fluid">
                  <div className="thili_div">
                    <div className="Back-page">
                      {" "}
                      <h1 className="title_heading">
                        Vendors({venderlist ? venderlist : ""})
                      </h1>{" "}
                    </div>
                    <div className="Thali_page_btn">
                      <div className="New_section">
                        {(user_Role.includes("ADM") ||
                          user_Role.includes("PRM") ||
                          user_Role.includes("PRO")) && (
                          <button
                            type="button"
                            className="btn thali_btn"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight"
                            aria-controls="offcanvasRight"
                            onClick={reset}
                          >
                            Add New Vendors
                          </button>
                        )}
                        &nbsp; &nbsp; &nbsp;{" "}
                        <button className="btn thali_btn csvbtn">
                          <CSVLink
                            data={csvData}
                            headers={csvHeaders}
                            filename={"Vendor.csv"}
                          >
                            Export List
                          </CSVLink>
                        </button>
                      </div>
                      &nbsp;
                    </div>
                  </div>
                </div>
                <div className="thali_count_list">
                  <div className="form-group has-search">
                    <span className="fa fa-search form-control-feedback"></span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search vendor by name"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>

                <div className="thali_table_section">
                  {loading ? (
                    // Render a loading indicator while data is being fetched
                    <div className="Loading-img">
                      <img src={loadergif} className="" />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <table className="table table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th
                          scope="col"
                          onClick={() => handleSort("vendername")}
                        >
                          <span className="thali_table">
                            {" "}
                            Vendors &nbsp; &nbsp;
                            {getSortIcon("vendername")}
                          </span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Contact person</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Contact NO</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">city</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">state</span>
                        </th>
                        {/* <th scope="col">
                          <span className="thali_table">country</span>
                        </th> */}
                        <th scope="col">
                          <span className="thali_table">zip</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">action</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortvendor.map((items) => {
                        return (
                          <tr key={items.id}>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>
                                  <img
                                    src={
                                      items.vendor_logo
                                        ? imgurl + items.vendor_logo
                                        : imgurl + "noimg.png"
                                    }
                                    className="img-firud img-setup"
                                  />
                                  {items.vendor_name ? items.vendor_name : "-"}
                                </li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>
                                  {items.contact_person
                                    ? items.contact_person
                                    : "-"}
                                </li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>
                                  {items.contact_phone
                                    ? items.contact_phone
                                    : "-"}
                                </li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>{items.city ? items.city : "-"}</li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>{items.state ? items.state : "-"}</li>
                              </ul>
                            </th>
                            {/* <th scope="row">
                              <ul className="thali_menu_list">
                                <li>{items.country ? items.country : "-"}</li>
                              </ul>
                            </th> */}
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>{items.zip_code ? items.zip_code : "-"}</li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                {(user_Role.includes("ADM") ||
                                  user_Role.includes("PRM") ||
                                  user_Role.includes("PRO")) && (
                                  <li>
                                    <div className="dropdown action_btn">
                                      <button
                                        className="btn btn-secondary dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton2"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i
                                          className="fa fa-ellipsis-h"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                      <ul
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton2"
                                      >
                                        <li>
                                          <a
                                            onClick={() => dataget(items)}
                                            className="dropdown-item"
                                            data-bs-toggle="offcanvas"
                                            data-bs-target="#offcanvasRight_edit"
                                            aria-controls="offcanvasRight_edit"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="19"
                                              height="15"
                                              viewBox="0 0 19 15"
                                              fill="none"
                                            >
                                              <path
                                                d="M17.125 8.65L15.35 6.875L16.075 6.15C16.2135 6.01667 16.3898 5.95 16.6039 5.95C16.818 5.95 16.9917 6.01667 17.125 6.15L17.85 6.875C17.9833 7.01352 18.05 7.18982 18.05 7.4039C18.05 7.61797 17.9833 7.79167 17.85 7.925L17.125 8.65ZM9 15V13.225L14.275 7.95L16.05 9.725L10.775 15H9ZM0.75 9.75C0.335787 9.75 0 9.41421 0 9C0 8.58579 0.335786 8.25 0.75 8.25H6.75C7.16421 8.25 7.5 8.58579 7.5 9C7.5 9.41421 7.16421 9.75 6.75 9.75H0.75ZM0.75 5.625C0.335786 5.625 0 5.28921 0 4.875C0 4.46079 0.335786 4.125 0.75 4.125H11C11.4142 4.125 11.75 4.46079 11.75 4.875C11.75 5.28921 11.4142 5.625 11 5.625H0.75ZM0.75 1.5C0.335786 1.5 0 1.16421 0 0.75C0 0.335786 0.335786 0 0.75 0H11C11.4142 0 11.75 0.335786 11.75 0.75C11.75 1.16421 11.4142 1.5 11 1.5H0.75Z"
                                                fill="#777391"
                                              />
                                            </svg>{" "}
                                            &nbsp; &nbsp;Edit Details
                                          </a>
                                        </li>
                                        <li onClick={() => dataget(items)}>
                                          <a
                                            className="dropdown-item"
                                            onClick={RemoveModal}
                                          >
                                            {" "}
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="18"
                                              height="20"
                                              viewBox="0 0 18 20"
                                              fill="none"
                                            >
                                              <path
                                                d="M3.47748 19.1493C3.01584 19.1493 2.61556 18.9804 2.27663 18.6427C1.93769 18.3049 1.76823 17.906 1.76823 17.4461V3.29769H1.49323C1.25283 3.29769 1.05079 3.21543 0.887127 3.05092C0.723444 2.8864 0.641602 2.68332 0.641602 2.44167C0.641602 2.20003 0.723444 1.99842 0.887127 1.83682C1.05079 1.67522 1.25283 1.59442 1.49323 1.59442H5.60465C5.60465 1.34987 5.68649 1.14579 5.85018 0.982191C6.01384 0.818608 6.21588 0.736816 6.45628 0.736816H11.5443C11.7842 0.736816 11.9871 0.819775 12.1531 0.985691C12.319 1.15161 12.4019 1.35452 12.4019 1.59442H16.5074C16.7478 1.59442 16.9498 1.67668 17.1135 1.84122C17.2772 2.00573 17.359 2.20881 17.359 2.45044C17.359 2.69209 17.2772 2.89371 17.1135 3.05529C16.9498 3.21689 16.7478 3.29769 16.5074 3.29769H16.2324V17.4461C16.2324 17.906 16.0629 18.3049 15.724 18.6427C15.385 18.9804 14.9848 19.1493 14.5231 19.1493H3.47748ZM3.47748 3.29769V17.4461H14.5231V3.29769H3.47748ZM6.09758 14.5461C6.09758 14.7694 6.17524 14.9582 6.33058 15.1125C6.48589 15.2668 6.67602 15.3439 6.90095 15.3439C7.12589 15.3439 7.31516 15.2668 7.46878 15.1125C7.62241 14.9582 7.69923 14.7694 7.69923 14.5461V6.17269C7.69923 5.94932 7.62057 5.75952 7.46325 5.60327C7.30594 5.44702 7.11481 5.36889 6.88988 5.36889C6.66494 5.36889 6.47666 5.44702 6.32503 5.60327C6.17339 5.75952 6.09758 5.94932 6.09758 6.17269V14.5461ZM10.3014 14.5461C10.3014 14.7694 10.38 14.9582 10.5374 15.1125C10.6947 15.2668 10.8858 15.3439 11.1107 15.3439C11.3357 15.3439 11.5249 15.2668 11.6786 15.1125C11.8322 14.9582 11.909 14.7694 11.909 14.5461V6.17269C11.909 5.94932 11.8309 5.75952 11.6748 5.60327C11.5187 5.44702 11.3276 5.36889 11.1014 5.36889C10.8753 5.36889 10.6854 5.44702 10.5318 5.60327C10.3782 5.75952 10.3014 5.94932 10.3014 6.17269V14.5461Z"
                                                fill="#777391"
                                              />
                                            </svg>
                                            &nbsp; &nbsp; &nbsp;Remove
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                )}
                              </ul>
                            </th>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {/* Pagination */}
                </div>
                <nav>
                  <ul className="pagination">
                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button className="page-link" onClick={handleFirstPage}>
                        First
                      </button>
                    </li>

                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        Previous
                      </button>
                    </li>

                    {getPageNumbers().map((pageNumber) => (
                      <li
                        className={`page-item ${
                          currentPage === pageNumber ? "active" : ""
                        }`}
                        key={pageNumber}
                      >
                        <button
                          className="page-link pagebutton"
                          onClick={() => handlePageChange(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    ))}

                    <li
                      className={`page-item ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        Next
                      </button>
                    </li>

                    <li
                      className={`page-item ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <button className="page-link" onClick={handleLastPage}>
                        Last
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* add vendor */}
      <div
        className="offcanvas offcanvas-end Dishes_canvas vendor_canvas"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            Add vendor{" "}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          {/* <img src={img_upload} className="img-firud" onClick={() => ref.current.click()} /> */}
          {/* <button onClick={() => ref.current.click()}>Upload</button> */}

          <div className="Dish_Name_list">
            <form className="row g-3" onSubmit={handleSubmit}>
              <div className="col-md-12">
                <div className="Img_upload vender_img">
                  <div>
                    {" "}
                    <img src={previewImage} className="img-firud " />
                  </div>
                  <div>
                    <input
                      id="img_upload"
                      type="file"
                      className="vender_input"
                      name="image"
                      onChange={handleChangeImg}
                    />
                  </div>
                </div>
                {/* {validationerror && updateImage === null &&
                  <p className="show_invalid_text" > Image is required</p>} */}
              </div>
              <div className="col-md-12">
                <div className="Form_box">
                  <label for="VendorName" className="form-label">
                    Vendor Name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your shop name"
                    className={`${
                      validationerror && !vendor
                        ? "validation_valid form-control"
                        : "form-control"
                    }`}
                    id="VendorName"
                    onChange={(e) => setVendor(e.target.value)}
                    value={vendor}
                  />
                  {validationerror && !vendor && (
                    <p className="show_invalid_text">Vendor Name is required</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="ContactPerson" className="form-label">
                    Contact Person
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter person name"
                    className={"form-control"}
                    id="ContactPerson"
                    value={name}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="ContactPhone" className="form-label">
                    Contact Phone
                  </label>
                  <input
                    type="number"
                    onChange={(e) => setNumber(e.target.value)}
                    placeholder="Enter phone number"
                    className={"form-control"}
                    id="ContactPhone"
                    value={number}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="Form_box">
                  <label for="State" className="form-label">
                    State
                  </label>
                  <Select
                    options={Statelist}
                    className={"dropdown"}
                    placeholder="Select State"
                    id="inputrice"
                    // styles={colourStyles}
                    value={selectedState}
                    // defaultValue={cleartype}
                    onChange={(e) => setSelectedState(e)}
                    isClearable
                    hideSelectedOptions={false}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="Form_box">
                  <label for="City" className="form-label">
                    City
                  </label>
                  <Select
                    options={Citylist}
                    // className={`${validationerror && !zoneid ? "validation_valid dropdown" : "dropdown"}`}
                    placeholder="Select City"
                    id="inputrice"
                    // styles={colourStyles}
                    className={"dropdown"}
                    value={selectedCity}
                    // defaultValue={cleartype}
                    onChange={(e) => setSelectedCity(e)}
                    isClearable
                    hideSelectedOptions={false}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="Form_box">
                  <label for="Zipcode" className="form-label">
                    Zip code{" "}
                  </label>
                  <input
                    type="text"
                    placeholder="Zip code"
                    onChange={(e) => setPincode(e.target.value)}
                    className={"form-control"}
                    id="Zipcode"
                    value={pincode}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputCaterer" className="form-label">
                    Vendor Notes
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Ingredient Note"
                    className={"form-control"}
                    id="inputDishName"
                    onChange={(e) => setGetVendor(e.target.value)}
                    value={getvendor}
                  />
                </div>
              </div>
              <div className="Ingredients_title">
                <h1></h1>
                <div className="Save_btn">
                  <button
                    type="button"
                    id="modal_close_btn"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button type="submit">Add Vendor</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* edit vendor */}
      <div
        className="offcanvas offcanvas-end Dishes_canvas vendor_canvas"
        tabindex="-1"
        id="offcanvasRight_edit"
        aria-labelledby="offcanvasRight_edit"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRight_edit">
            Edit vendor{" "}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="Img_upload">
            {/* <img src={img_upload} className="img-firud" onClick={() => ref.current.click()} /> */}
            {/* <button onClick={() => ref.current.click()}>Upload</button> */}
            <img src={image} className="img-firud uplode_img" />
            <input
              type="file"
              name="image"
              id="img_upload"
              onChange={hanleupdateurl}
            />
          </div>
          <div className="Dish_Name_list">
            <form className="row g-3" onSubmit={updatehandleSubmit}>
              <div className="col-md-12">
                <div className="Form_box">
                  <label for="VendorName" className="form-label">
                    Vendor Name
                  </label>
                  <input
                    value={vendor}
                    type="text"
                    placeholder="Vendor Name"
                    className={`${
                      validationerror && !vendor
                        ? "validation_valid form-control"
                        : "form-control"
                    }`}
                    id="VendorName"
                    onChange={(e) => setVendor(e.target.value)}
                  />
                  {validationerror && !vendor && (
                    <p className="show_invalid_text">Vendor Name is required</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="ContactPerson" className="form-label">
                    Contact Person
                  </label>
                  <input
                    type="text"
                    value={name}
                    placeholder="Contact Person"
                    className={"form-control"}
                    id="ContactPerson"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="ContactPhone" className="form-label">
                    Contact Phone
                  </label>
                  <input
                    type="number"
                    value={number}
                    placeholder="Vendor Name"
                    className={"form-control"}
                    id="ContactPhone"
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-6">
                <div className="Form_box">
                  <label for="State" className="form-label">
                    State
                  </label>
                  <Select
                    options={Statelist}
                    className={"dropdown"}
                    placeholder="Select Zone"
                    id="inputrice"
                    // styles={colourStyles}
                    value={Statelist.filter(function (option) {
                      return option.label === selectedState;
                    })}
                    onChange={(e) => setSelectedState(e.label)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="Form_box">
                  <label for="City" className="form-label">
                    City
                  </label>
                  <Select
                    options={Citylist}
                    className={"dropdown"}
                    placeholder="Select Zone"
                    id="inputrice"
                    // styles={colourStyles}
                    value={Citylist.filter(function (option) {
                      return option.label === selectedCity;
                    })}
                    onChange={(e) => setSelectedCity(e.label)}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="Form_box">
                  <label for="Zipcode" className="form-label">
                    Zip code{" "}
                  </label>
                  <input
                    type="text"
                    placeholder="Zip code"
                    className={"form-control"}
                    id="Zipcode"
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputCaterer" className="form-label">
                    Vendor Notes
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Ingredient Note"
                    className={"form-control"}
                    id="inputDishName"
                    onChange={(e) => setGetVendor(e.target.value)}
                    value={getvendor}
                  />
                </div>
              </div>
              <div className="Ingredients_title">
                <h1></h1>
                <div className="Save_btn">
                  <button
                    id="modal_close_btn2"
                    type="button"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button>update vender</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal
        isOpen={RemoveIsOpen}
        onAfterOpen={RemoveOpenModal}
        onRequestClose={RemovecloseModal}
        style={customModal}
        contentLabel="Remove Modal"
        id="edit_popup"
      >
        <div className="popup_modal">
          <div className="Remove_from">
            <img src={remove} className="img-firud" />
            <h1>Remove {vendor} Vendors</h1>

            <p>
              Do you really want to remove Vendors? This action cannot be undone
            </p>
            <div className="Thali_page_btn_from btn-div">
              <button onClick={RemovecloseModal} className="btn thali_btn">
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => deleteVendorData(id)}
                className="btn thali_btn"
              >
                Remove Vendors
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
