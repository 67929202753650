import React, { useEffect, useState } from "react";
import Sidebarmenu from "../../Sidebar/Sidebarmenu";
import Header from "../Header/Header";
import {
  Thalireg,
  getPortionList,
  getZoneList,
  createRegisterthali,
  fmb_schedule_url,
} from "../../../Redux/Reducer/AuthReducer";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import Modal from "react-modal";
import loadergif from "../../../img/landing/loader123.gif";
import editicon from "../../../img/editicon.png";

export default function THaaliRegistered() {
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isFocused ? "#AE8340" : null,
        boxShadow: isFocused ? "none" : "none",
        color: "#333333",
      };
    },
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [thalilistData, setThaliListData] = useState("");
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [thaliview, setThaliView] = useState("");
  const [scheduleday, setScheduleDay] = useState("");
  const [portiontypecode, setPortionTypeCode] = useState("");
  const [pickuplocationcode, setPickupLocationCode] = useState("");
  const [WantRoti, setWantRoti] = useState("");
  const [wantrice, setWantRice] = useState("");
  const [wantMithas, setWantMithas] = useState("");
  const [isactive, setIsActive] = useState("");
  const [allowskiproti, setAllowSkipRoti] = useState("");
  const [allowskiprice, setAllowSkiPrice] = useState("");
  const [allowskipmithas, setAllowSkipMithas] = useState("");
  const getthalireglist = () => {
    setLoading(true);
    dispatch(Thalireg())
      .then((res) => {
        if (res?.payload?.status === true) {
          setThaliListData(res?.payload?.data?.scheduleList);
          const formattedDate = new Date(
            res?.payload?.data?.start_date
          ).toLocaleDateString();
          const formattedDate2 = new Date(
            res?.payload?.data?.end_date
          ).toLocaleDateString();
          // setActiveTab("current");
          setStartDate(formattedDate);
          setEndDate(formattedDate2);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .finally(() => {
        setLoading(false); // Set loading to false when data fetching is complete
      });
  };

  useEffect(() => {
    getthalireglist();
    portionList();
    zoneList();
  }, []);

  const handleRotiChange = (e, index, isChecked) => {
    const { checked } = e.target;

    setThaliListData((prevuser) =>
      prevuser.map((item, i) =>
        i === index ? { ...item, want_roti: checked ? "1" : "0" } : item
      )
    );
  };

  const handleMithasChange = (e, index, isChecked) => {
    const { checked } = e.target;

    setThaliListData((prevuser) =>
      prevuser.map((item, i) =>
        i === index ? { ...item, want_mithas: checked ? "1" : "0" } : item
      )
    );
  };

  const [portionlist, setPortionList] = useState([]);
  const portionList = () => {
    dispatch(getPortionList()).then((res) => {
      if (res?.payload?.status === true) {
        setPortionList(res?.payload?.data);
        // console.log('',res.payload.data)
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const [zonedata, setZonedata] = useState([]);
  const zoneList = () => {
    dispatch(getZoneList()).then((res) => {
      if (res?.payload?.status === true) {
        setZonedata(res?.payload?.data);
        // console.log('',res.payload.data)
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };
  
  let portionListData = [];
  portionListData = portionlist.map((item) => ({
    label: item.portion_type_name,
    value: item.portion_type_code,
  }));

  let zoneListData = [];
  zoneListData = zonedata.map((item) => ({
    label: item.pickup_location_name,
    value: item.pickup_location_code,
  }));

  const handlezoneIdChange = (index, label) => {
    const updatedzone = thalilistData.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          pickup_location_code: label,
        };
      }
      return item;
    });
    setThaliListData(updatedzone);
  };

  const handleRiceChange = (e, index, isChecked) => {
    const { checked } = e.target;

    setThaliListData((prevuser) =>
      prevuser.map((item, i) =>
        i === index ? { ...item, want_rice: checked ? "1" : "0" } : item
      )
    );
  };

  const handlezoneIdChange2 = (index, label) => {
    const updatedzone = thalilistData.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          portion_type_code: label,
        };
      }
      return item;
    });
    setThaliListData(updatedzone);
  };

  const handleTHALIChange = (e, index, isChecked) => {
    const { checked } = e.target;

    setThaliListData((prevuser) =>
      prevuser.map((item, i) =>
        i === index ? { ...item, is_active: checked ? "1" : "0" } : item
      )
    );
  };

  const handleSubmituser = (e) => {
    e.preventDefault();
    const requestData = thalilistData.map((item, i) => ({
      schedule_id: item?.schedule_id,
      want_rice: item.allow_skip_rice == "0" ? -1 : item.want_rice == -1 ? item.user.want_rice : item.want_rice,
      want_roti: item.allow_skip_roti == "0" ? -1 : item.want_roti == -1 ? item.user.want_roti : item.want_roti,
      want_mithas: item.allow_skip_mithas == "0" ? -1 : item.want_mithas == -1 ? item.user.want_mithas : item.want_mithas,
      is_active: item.is_active,
      pickup_location_code: item.pickup_location_code == "-1" ? item.user.pickup_location_code : item.pickup_location_code,
      portion_type_code: item.portion_type_code == "-1" ? item.user.portion_type_code : item.portion_type_code,
      source: "Web",
      user_id: localStorage.getItem("access_id"),
      created_user: localStorage.getItem("access_id"),
      updated_user: localStorage.getItem("access_id"),
      created_at: new Date (),
      updated_at: new Date ()
    }));

    getselectdatachange(requestData);
    setTimeout(function () {
      getthalireglist();
    }, 1000);
  };

  const getselectdatachange = (requestBody) => {
    setLoading(true);
    fetch(fmb_schedule_url + "admin/schedule-register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json", // Assuming you're sending form data
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status == true) {
          // setIngredientsList("")
          console.log("new data ", data.message);
          toast.success(data.message);
          // console.log('blank2 ',  start );
        } else {
          // toast.error("Places select Date");
          console.log("error ", data);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when data fetching is complete
      });
  };
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  function afterOpenModal() {}

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const newdate = convertDateForIos(scheduleday);
  const date = new Date(newdate);
  const day = date.toLocaleString("en-US", { weekday: "long" });
  const month = date.toLocaleString("en-US", { month: "long" });
  const numericDate = date.getDate();
  const twoDigitYear = date.getFullYear();

  const dataget = (item) => {
    console.log(item, "item");

    if (item) {
      // Conditionally set portion_type_code state
      if (item.portion_type_code === "-1") {
        setPortionTypeCode(item.user.portion_type_code);
      } else {
        setPortionTypeCode(item.portion_type_code);
      }

      // Conditionally set pickup_location_code state
      if (item.pickup_location_code == "-1") {
        setPickupLocationCode(item.user.pickup_location_code);
      } else {
        setPickupLocationCode(item.pickup_location_code);
      }

      if (item.want_roti == "-1") {
        setWantRoti(item.user.want_roti);
      } else {
        setWantRoti(item.want_roti);
      }

      if (item.want_rice == "-1") {
        setWantRice(item.user.want_rice);
      } else {
        setWantRice(item.want_rice);
      }

      if (item.want_mithas == "-1") {
        setWantMithas(item.user.want_mithas);
      } else {
        setWantRice(item.want_mithas);
      }

      setIsActive(item.is_active);
      // Set other state values
      setScheduleDay(item.schedule_day);
      setThaliView(item);
      setAllowSkiPrice(item.allow_skip_rice);
      setAllowSkipRoti(item.allow_skip_roti);
      setAllowSkipMithas(item.allow_skip_mithas);
    }
  };

  const handleToggleChange = (e) => {
    const newValue = WantRoti === "1" ? "0" : "1";
    setWantRoti(newValue);
  };

  const handleToggleChange2 = (e) => {
    const newValue = wantrice === "1" ? "0" : "1";
    setWantRice(newValue);
  };

  const handleToggleChange3 = (e) => {
    const newValue = wantMithas === "1" ? "0" : "1";
    setWantMithas(newValue);
  };

  const handleisactive = (checked) => {
    setIsActive(checked);
  };


  const formatDate = (dateString) => {
    const currentDateTime = new Date(); // Get the current date and time
    currentDateTime.setHours(0,0,0,0)

    const firstParts = dateString.split("/");
    const rsvp_date = new Date(firstParts[2], firstParts[0] - 1, firstParts[1]);
    const currentDayDateOnly = currentDateTime.toISOString().split("T")[0]
    const rsvpDayDate = rsvp_date.toISOString().split("T")[0]

    if (currentDayDateOnly == rsvpDayDate) {
      return true;
    }
    return false;
  };

  function formatDate2(dateString) {
    var currentDateTime = new Date(); // Get the current date and time
    var firstParts = dateString.split("/");
    var rsvp_date = new Date(firstParts[2], firstParts[0] - 1, firstParts[1]);
    return currentDateTime > rsvp_date;
  }

  const isTimeGreaterOrEqual = (targetTime) => {
    const now = new Date();
    const targetTimeParts = targetTime?.split(".");

    let hours = parseInt(targetTimeParts[0]);
    const minutes = parseInt(targetTimeParts[1]?.split(" ")[0]);
    const ampm = targetTimeParts[1]?.split(" ")[1];

    if (ampm === "PM" && hours < 12) {
      hours += 12;
    }
    const targetDateTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      hours,
      minutes
    );
    return now >= targetDateTime;
  };

  function formatRSVPDisplayDate(dateString) {
    var dateArr = dateString.split("/");
    return dateArr[0] + "/" + dateArr[1];
  }

  function formatRSVPDisplayTime(rsvp_time) {
    return rsvp_time.replace(".", ":");
  }

  function formatRSVPDate(dateString) {
    var currentDateTime = new Date(); // Get the current date and time
    var firstParts = dateString.split("/");
    var rsvp_date = new Date(firstParts[2], firstParts[0] - 1, firstParts[1]);
    var NumberOfDays = Math.round(
      (rsvp_date - currentDateTime) / (1000 * 60 * 60 * 24)
    );

    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var day = days[rsvp_date.getDay()];

    if (NumberOfDays < 7) {
      return NumberOfDays === -1 ? "Today" : NumberOfDays === 0 ? "Tomorrow" : day;
    } else {
      return formatRSVPDisplayDate(dateString);
    }
  }

  function convertDateForIos(date) {
    var arr = date.split(/[- :]/);
    date = new Date(arr[2], arr[0] - 1, arr[1]);
    return date;
  }

  // { shouldHide ? `Registration ended on ${formatRSVPDisplayDate(item?.rsvp_date)}` : `Registration ends ${formatRSVPDate(item?.rsvp_date)} at ${item?.rsvp_time}`}

  const handleSubmit = (e) => {
    e.preventDefault();
    const daylist = new Date(scheduleday);
    const sameday = daylist
      .toLocaleString("en-US", {
        weekday: "long",
      })
      .substring(0, 3);
    const entries = [
      {
        day: sameday,
        date: scheduleday,
        thaali_register: isactive ? "YES" : "NO",
        portion: portiontypecode,
        zone: pickuplocationcode,
        want_rice: wantrice ? "YES" : "NO",
        want_roti: WantRoti ? "YES" : "NO",
        full_name: localStorage.getItem("author_type"),
        its_no: localStorage.getItem("its_id"),
      },
    ];

    const requestData = {
      date: new Date(), // Replace this with the appropriate date value if needed
      entries: entries,
    };
    handleRequestSubmit(requestData);
    console.log("requestData", requestData);
  };

  const handleRequestSubmit = (requestData) => {
    // setLoading(true);

    fetch(fmb_schedule_url + "admin/generate-email", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json", // Assuming you're sending form data
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status == true) {
          // setIngredientsList("")
          console.log("new data ", data.message);
          toast.success(data.message);
          // console.log('blank2 ',  start );
          setIsOpen(false);
        } else {
          // toast.error("Places select Date");
          console.log("error ", data);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        // setLoading(false); // Set loading to false when data fetching is complete
      });
  };
  return (
    <div>
      <Header />
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarmenu />
        <section className="main_section">
          <div className="custam">
            <div className="main_section2">
              <div className="ExportInventory thali_section Thali_page">
                <div className="container-fluid">
                  <div className="thili_div">
                    <div className="Back-page">
                      <h1 className="title_heading">
                        Thaali Registration
                        {/* <span className="Date_list">
                          {startDate} - {endDate}
                        </span>{" "} */}
                      </h1>{" "}
                    </div>

                    <button
                      className="btn thali_btn"
                      onClick={handleSubmituser}
                    >
                      Submit Registration{" "}
                    </button>
                  </div>
                </div>
                <div className="container-fluid">
                  {loading ? (
                    // Render a loading indicator while data is being fetched
                    <div className="Loading-img">
                      <img src={loadergif} className="" />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className="Thaali-reg">
                    {Array.isArray(thalilistData) &&
                      thalilistData.map((item, index) => {
                        // const date = new Date(item.schedule_day);
                        const date = convertDateForIos(item.schedule_day);
                        const day = date.toLocaleString("en-US", {
                          weekday: "long",
                        });
                        const month = date.toLocaleString("en-US", {
                          month: "long",
                        });
                        const numericDate = date.getDate();
                        const twoDigitYear = date.getFullYear();

                        const startIndex = item?.menu?.indexOf('"') + 1;
                        const endIndex = item?.menu?.lastIndexOf('"');
                        const extractedText = item?.menu?.substring(
                          startIndex,
                          endIndex
                        );
                        {
                          /* console.log(extractedText, 'ddd'); */
                        }

                        const menuArray = extractedText
                          ?.split(/,|"/)
                          .map((item) => item?.replace(/-/g, ""));
                        const shouldHide = formatDate(item.rsvp_date)
                          ? isTimeGreaterOrEqual(item.rsvp_time)
                          : formatDate2(item.rsvp_date);
                        return (
                          <div className={item.is_niyaaz ? "Thaali-reg Thaali-myBg" : "Thaali-reg"}>
                          <div
                            className={`card ${shouldHide ? "hidden-row Thaali-myBg" : "Thaali-myBg"}`}
                            key={item.id}
                          >
                            <div className="Calendar_card spacemargin">
                              <h6>{day}</h6>
                              <h1>{numericDate}</h1>
                              <p>
                                {month}, {twoDigitYear}
                              </p>
                            </div>

                     

                            <div className="reg-menu-div spacediv spacemargin">
                            { item.is_niyaaz == "1"  &&
                            <div className="thali-checkbox ">
                                  <h5 class="miqaat-thaali-heding">{`MIQAAT-THAALI`}</h5>
                            </div>
                            }
                              <h5 class="thali-heding">Menu:</h5>
                              <ul className="thalireg_menu_list">
                                {menuArray
                                  ?.filter(
                                    (dish_name) => dish_name.trim() !== ""
                                  ) // Remove blank items
                                  .map((dish_name, index) => (
                                    <li key={index}>{dish_name}</li>
                                  ))}

                                {item.fateha_name ? (
                                  <>
                                    <h5 class="thali-heding"></h5>
                                    <h5 class="thali-heding">Fateha Name</h5>
                                    <li>{item.fateha_name}</li>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </ul>
                            </div>

                            <div className="portion-div  spacemargin">
                              <ul className="thali_menu_list view_Registeruser">
                                <h5 class="thali-heding">Portion</h5>
                                <li className="Form_box">
                                  {item.portion_type_code === "-1" ? (
                                    <>
                                      <Select
                                        options={
                                          portionListData.filter(function(items) {
                                            return parseInt(items.value) <= parseInt(item.user.default_portion)
                                        })}
                                        className="dropdown"
                                        placeholder="Select Portion"
                                        id="inputrice"
                                        styles={colourStyles}
                                        onChange={(selectedOption) =>
                                          handlezoneIdChange2(
                                            index,
                                            selectedOption.value
                                          )
                                        }
                                        value={
                                          item.user.portion_type_code
                                            ? portionListData.find(
                                                (option) =>
                                                  option.value ===
                                                  item.user.portion_type_code
                                              )
                                            : null
                                        }
                                        isDisabled={shouldHide}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <Select
                                        options={
                                          portionListData.filter(function(items) {
                                            return parseInt(items.value) <= parseInt(item.user.default_portion)
                                        })}
                                        className="dropdown"
                                        placeholder="Select Portion"
                                        id="inputrice"
                                        styles={colourStyles}
                                        onChange={(selectedOption) =>
                                          handlezoneIdChange2(
                                            index,
                                            selectedOption.value
                                          )
                                        }
                                        value={
                                          item.portion_type_code
                                            ? portionListData.find(
                                                (option) =>
                                                  option.value ===
                                                  item.portion_type_code
                                              )
                                            : null
                                        }
                                        isDisabled={shouldHide}
                                      />
                                    </>
                                  )}
                                </li>
                              </ul>
                            </div>

                            <div className="zone-div  spacemargin">
                              <ul className="thali_menu_list view_Registeruser">
                                <h5 class="thali-heding">Zone</h5>
                                {/* { item.is_niyaaz != 1 ? */}
                                <li className="Form_box">
                                  {item.pickup_location_code === "-1" ? (
                                    <>
                                      <Select
                                        options={zoneListData}
                                        className="dropdown"
                                        placeholder="Select Zone"
                                        id="inputrice"
                                        styles={colourStyles}
                                        onChange={(selectedOption) =>
                                          handlezoneIdChange(
                                            index,
                                            selectedOption.value
                                          )
                                        }
                                        value={
                                          item.user.pickup_location_code
                                            ? zoneListData.find(
                                                (option) =>
                                                  option.value ===
                                                  item.user.pickup_location_code
                                              )
                                            : null
                                        }
                                        isDisabled={shouldHide}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <Select
                                        options={zoneListData}
                                        className="dropdown"
                                        placeholder="Select Zone"
                                        id="inputrice"
                                        styles={colourStyles}
                                        onChange={(selectedOption) =>
                                          handlezoneIdChange(
                                            index,
                                            selectedOption.value
                                          )
                                        }
                                        value={
                                          item.pickup_location_code
                                            ? zoneListData.find(
                                                (option) =>
                                                  option.value ===
                                                  item.pickup_location_code
                                              )
                                            : null
                                        }
                                        isDisabled={shouldHide}
                                      />
                                    </>
                                  )}
                                </li>
                                 {/* : */}
                                {/* <h7 class="miqaat-zone-heding">Saifee-Masjid</h7> */}
                                {/* } */}
                              </ul>
                            </div>
                            <div className="rice-div  spacemargin">
                              <ul className="thali_menu_list">
                                <li>
                                  <>
                                    <div className="Register_user_input2 Register_user_input">
                                      <h5 class="thali-heding">Want Rice</h5>
                                      <div className="form-check form-switch">
                                        {item.allow_skip_rice == "0" ? (
                                          <>-</>
                                        ) : (
                                          <>
                                            {item.want_rice == "-1" ? (
                                              <>
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  role="switch"
                                                  id={`rice_check_${index}`}
                                                  value={item.user.want_rice}
                                                  checked={
                                                    item.user.want_rice == "1"
                                                  }
                                                  onChange={(e) =>
                                                    handleRiceChange(
                                                      e,
                                                      index,
                                                      item.user.want_rice == "1"
                                                    )
                                                  }
                                                  disabled={shouldHide}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  role="switch"
                                                  id={`rice_check_${index}`}
                                                  value={item.want_rice}
                                                  checked={
                                                    item.want_rice == "1"
                                                  }
                                                  onChange={(e) =>
                                                    handleRiceChange(
                                                      e,
                                                      index,
                                                      item.want_rice == "1"
                                                    )
                                                  }
                                                  disabled={shouldHide}
                                                />
                                              </>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                </li>
                              </ul>
                            </div>

                            <div className="roti-div  spacemargin">
                              <ul className="thali_menu_list">
                                <li>
                                  <>
                                    <div className="Register_user_input2 Register_user_input">
                                      <h5 class="thali-heding">Want Roti</h5>
                                      <div className="form-check form-switch">
                                        {item.allow_skip_roti == "0" ? (
                                          <>-</>
                                        ) : (
                                          <>
                                            {item.want_roti == "-1" ? (
                                              <>
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  role="switch"
                                                  id={`roti_check_${index}`}
                                                  value={item.user.want_roti}
                                                  checked={
                                                    item.user.want_roti == "1"
                                                  }
                                                  onChange={(e) =>
                                                    handleRotiChange(
                                                      e,
                                                      index,
                                                      item.user.want_roti == "1"
                                                    )
                                                  }
                                                  disabled={shouldHide}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  role="switch"
                                                  id={`roti_check_${index}`}
                                                  value={item.want_roti}
                                                  checked={
                                                    item.want_roti == "1"
                                                  }
                                                  onChange={(e) =>
                                                    handleRotiChange(
                                                      e,
                                                      index,
                                                      item.want_roti == "1"
                                                    )
                                                  }
                                                  disabled={shouldHide}
                                                />
                                              </>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                </li>
                              </ul>
                            </div>

                            { item.allow_skip_mithas == "1" &&
                            <div className="roti-div  spacemargin">
                              <ul className="thali_menu_list">
                                <li>
                                  <>
                                    <div className="Register_user_input2 Register_user_input">
                                      <h5 class="thali-heding">Want Mithas</h5>
                                      <div className="form-check form-switch">
                                        {item.allow_skip_mithas == "0" ? (
                                          <>-</>
                                        ) : (
                                          <>
                                            {item.want_mithas == "-1" ? (
                                              <>
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  role="switch"
                                                  id={`roti_check_${index}`}
                                                  value={item.user.want_mithas}
                                                  checked={
                                                    item.user.want_mithas == "1"
                                                  }
                                                  onChange={(e) =>
                                                    handleMithasChange(
                                                      e,
                                                      index,
                                                      item.user.want_mithas == "1"
                                                    )
                                                  }
                                                  disabled={shouldHide}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  role="switch"
                                                  id={`roti_check_${index}`}
                                                  value={item.want_mithas}
                                                  checked={
                                                    item.want_mithas == "1"
                                                  }
                                                  onChange={(e) =>
                                                    handleMithasChange(
                                                      e,
                                                      index,
                                                      item.want_mithas == "1"
                                                    )
                                                  }
                                                  disabled={shouldHide}
                                                />
                                              </>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                </li>
                              </ul>
                            </div>
                            }

                            <div className="thali-checkbox spacemargin">
                              <h5 class="thali-heding">{item.is_niyaaz ? "Attending" : "Register Thaali"}</h5>
                              <ul className="thali_menu_list">
                                <li>
                                  <div className="THALI-REGISTERED2-check THALI-REGISTERED-check">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`is_active_${index}`}
                                        value={item.is_active}
                                        checked={item.is_active == true}
                                        onChange={(e) =>
                                          handleTHALIChange(
                                            e,
                                            index,
                                            item.is_active == true
                                          )
                                        }
                                        disabled={shouldHide}
                                      />
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="thali-checkbox ">
                              <i class="registration-heding">
                                {shouldHide
                                  ? `Registration ended on ${formatRSVPDisplayDate(
                                      item?.rsvp_date
                                    )} at ${formatRSVPDisplayTime(item?.rsvp_time)}`
                                  : `Registration ends ${formatRSVPDate(
                                      item?.rsvp_date
                                    )} at ${formatRSVPDisplayTime(item?.rsvp_time)}`}
                              </i>
                            </div>
                            {/* <div className="thali-checkbox ">
                              <h6 class="notes-heding">{item?.notes !== "" ? item?.notes : ''}</h6>
                            </div> */}
                            <div className="thali-checkbox ">
                              {" "}
                              {item.notes ? (
                                <>
                                  <h5 class="notes-heding">{`Notes: ${item.notes}`}</h5>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="rice-div spacediv ">
                              {shouldHide && (
                                <div onClick={() => dataget(item)}>
                                  <button
                                    type="button"
                                    className="btn thali_btn"
                                    onClick={openModal}
                                    disabled
                                  >
                                    Request Admin
                                  </button>
                                </div>
                              )}
                            </div>
                            </div>
                          </div>
                        );
                      })}

                    <div className="Thali_page_btn_from">
                      &nbsp; &nbsp; &nbsp;
                      {!loading ? (
                        <button
                          className="btn thali_btn"
                          onClick={handleSubmituser}
                        >
                          Submit Registration{" "}
                        </button>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </div>

                {/* <nav>
<ul className="pagination">
<li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
<button className="page-link" onClick={handleFirstPage}>
First
</button>
</li>
<li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
<button
className="page-link"
onClick={() => handlePageChange(currentPage - 1)}
>
Previous
</button>
</li>

{getPageNumbers().map((pageNumber) => (
<li
className={`page-item  ${currentPage === pageNumber ? 'active' : ''}`}
key={pageNumber}
>
<button
className="page-link pagebutton"
onClick={() => handlePageChange(pageNumber)}
>
{pageNumber}
</button>
</li>
))}

<li
className={`page-item ${currentPage === pageCount ? 'disabled' : ''}`}
>
<button
className="page-link"
onClick={() => handlePageChange(currentPage + 1)}
>
Next
</button>
</li>
<li
className={`page-item ${currentPage === pageCount ? 'disabled' : ''}`}
>
<button className="page-link" onClick={handleLastPage}>
Last
</button>
</li>
</ul>
</nav> */}
              </div>
            </div>
          </div>
        </section>

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          id="edit_register"
        >
          <div className="popup_modal">
            <div className="d-flex justify-content-between align-items-center">
              <div className="Popup_heding">
                <img src={editicon} className="img-firud" />
                &nbsp; &nbsp; &nbsp;&nbsp;
                <div className="edit_div">
                  <h1>View Thaali Registration Details</h1>
                  <p>To view thali Registration data for Admin to Request</p>
                </div>
              </div>
              <button onClick={closeModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M6.99967 8.19364L1.82142 13.3719C1.65149 13.5418 1.45257 13.6268 1.22467 13.6268C0.996772 13.6268 0.797855 13.5418 0.627922 13.3719C0.458005 13.202 0.373047 13.0031 0.373047 12.7752C0.373047 12.5473 0.458005 12.3483 0.627922 12.1784L5.8062 7.00016L0.627922 1.82191C0.458005 1.65198 0.373047 1.45306 0.373047 1.22516C0.373047 0.99726 0.458005 0.798344 0.627922 0.62841C0.797855 0.458494 0.996772 0.373535 1.22467 0.373535C1.45257 0.373535 1.65149 0.458494 1.82142 0.62841L6.99967 5.80668L12.1779 0.62841C12.3479 0.458494 12.5468 0.373535 12.7747 0.373535C13.0026 0.373535 13.2015 0.458494 13.3714 0.62841C13.5413 0.798344 13.6263 0.99726 13.6263 1.22516C13.6263 1.45306 13.5413 1.65198 13.3714 1.82191L8.19315 7.00016L13.3714 12.1784C13.5413 12.3483 13.6263 12.5473 13.6263 12.7752C13.6263 13.0031 13.5413 13.202 13.3714 13.3719C13.2015 13.5418 13.0026 13.6268 12.7747 13.6268C12.5468 13.6268 12.3479 13.5418 12.1779 13.3719L6.99967 8.19364Z"
                    fill="#2E294E"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="Registers_tabal">
            {/* <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">
                    {" "}
                    <span className="thali_table">Day & Date</span>
                  </th>
                  <th scope="col">
                    <span className="thali_table">Portion</span>
                  </th>
                  <th scope="col">
                    <span className="thali_table">Zone</span>
                  </th>
                  <th scope="col">
                    <span className="thali_table">Roti</span>
                  </th>
                  <th scope="col">
                    <span className="thali_table">RIce</span>
                  </th>
                  <th scope="col">
                    <span className="thali_table">Thali Registerd</span>
                  </th>
                </tr>
              </thead>
              <tbody> */}

            <div className="card-flex">
              <div className="card">
                <div className="manu-card">
                  <div className="Calendar_card">
                    {" "}
                    <h6>{day}</h6>
                    <h1>{numericDate}</h1>
                    <p>
                      {month}, {twoDigitYear}
                    </p>
                  </div>
                </div>
                <div className="manu-card">
                  {" "}
                  <h5 className="thali-heding">Portion</h5>
                  <ul className="thali_menu_list view_Registeruser">
                    <li className="Form_box">
                      <Select
                        options={portionListData}
                        className="dropdown"
                        placeholder="Select Portion"
                        id="inputrice"
                        styles={colourStyles}
                        onChange={(selectedOption) =>
                          setPortionTypeCode(selectedOption.label)
                        }
                        value={portionListData.find(
                          (option) => option.value === portiontypecode
                        )}
                      />
                    </li>
                  </ul>
                </div>
                <div className="manu-card">
                  <h5 className="thali-heding">Zone</h5>
                  <ul className="thali_menu_list view_Registeruser">
                    <li className="Form_box">
                      <Select
                        options={zoneListData}
                        className="dropdown"
                        placeholder="Select Zone"
                        id="inputrice"
                        styles={colourStyles}
                        onChange={(selectedOption) =>
                          setPickupLocationCode(selectedOption.label)
                        }
                        value={zoneListData.find(
                          (option) => option.value === pickuplocationcode
                        )}
                      />
                    </li>
                  </ul>
                </div>
                <div className="manu-card">
                  <h5 className="thali-heding">Roti</h5>
                  <ul className="thali_menu_list">
                    {allowskiprice == "0" ? (
                      <li>NA</li>
                    ) : (
                      <>
                        <li>
                          <div className="Register_user_input">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id={`roti_check`}
                                // value={WantRoti}
                                checked={WantRoti == true}
                                onChange={(e) => handleToggleChange()}
                              />
                            </div>
                          </div>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
                <div className="manu-card">
                  <h5 className="thali-heding">Rice</h5>
                  <ul className="thali_menu_list">
                    {allowskiprice == "0" ? (
                      <li>NA</li>
                    ) : (
                      <>
                        <li>
                          <div className="Register_user_input">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id={`rice_check`}
                                // value={wantrice}
                                checked={wantrice == true}
                                onChange={(e) => handleToggleChange2()}
                              />
                            </div>
                          </div>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
                <div className="manu-card">
                  <h5 className="thali-heding">Thaali Register</h5>
                  <ul className="thali_menu_list">
                    <li>
                      <div className="THALI-REGISTERED-check">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`is_active_`}
                            value={isactive}
                            checked={isactive}
                            onChange={(e) => handleisactive(e.target.checked)}
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* </tbody>
            </table> */}
          </div>
          <div className="Thali_page_btn_from">
            <button
              type="button"
              onClick={closeModal}
              className="btn thali_btn"
            >
              Cancel
            </button>
            &nbsp; &nbsp; &nbsp;
            <button className="btn thali_btn" onClick={handleSubmit}>
              Request Admin
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
}
