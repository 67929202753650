import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
// const api_url = "https://nizr0tz0n8.execute-api.us-east-1.amazonaws.com/";
export const api_url = "http://3.18.242.37:7000/";

// export const menu_schedule = "https://klxejblirl.execute-api.us-east-1.amazonaws.com/";
// export const feed_back = "https://v7vpq6ntyd.execute-api.us-east-1.amazonaws.com/";
// export const Inventory_list = "https://cnaz5741sk.execute-api.us-east-1.amazonaws.com/";
// export const procurment_url = "https://r8t2qgc4ka.execute-api.us-east-1.amazonaws.com/";
// export const fmb_schedule_url = "https://w0jx1qq4re.execute-api.us-east-1.amazonaws.com/";
// export const user_management = "https://25noys0q1a.execute-api.us-east-1.amazonaws.com/";
// export const fmb_food_items_dish = "https://kwp3mblrb1.execute-api.us-east-1.amazonaws.com/";
// export const fmb_food_items_ingredients = "https://np71xqzkrl.execute-api.us-east-1.amazonaws.com/";
// export const menu_schedul = "https://pgs3jsn8d3.execute-api.us-east-1.amazonaws.com/";
// export const user_catera = "https://p6awwhqeaa.execute-api.us-east-1.amazonaws.com/";
// fmb-report
// fmb-schedule-api
// fmb-menu-schedule

export const menu_schedule =
  "https://fw30jwmqk7.execute-api.us-east-1.amazonaws.com/";
export const feed_back =
  "https://roekuc0eef.execute-api.us-east-1.amazonaws.com/";
export const Inventory_list =
  "https://gded2u6sw3.execute-api.us-east-1.amazonaws.com/";
export const procurment_url =
  "https://yd7sd4l0ee.execute-api.us-east-1.amazonaws.com/";
export const fmb_schedule_url =
  "https://b83bp0dykd.execute-api.us-east-1.amazonaws.com/";
//export const user_management = "http://localhost:3000/"
  // "https://pgs3jsn8d3.execute-api.us-east-1.amazonaws.com/";
export const user_management = 
   "https://pgs3jsn8d3.execute-api.us-east-1.amazonaws.com/";
export const fmb_food_items_dish =
  "https://mverflrzc2.execute-api.us-east-1.amazonaws.com/";
export const fmb_food_items_ingredients =
  "https://eljcz7k2dg.execute-api.us-east-1.amazonaws.com/";
// export const menu_schedul = "https://pgs3jsn8d3.execute-api.us-east-1.amazonaws.com/";
export const user_catera =
  "https://i62ajpqbrb.execute-api.us-east-1.amazonaws.com/";

var token = localStorage.getItem("access_token");
const access_id = localStorage.getItem("access_id");


const initialState = {
  loading: false,
  error: "",
  user: "",
  msg: "",
  email: "",
  password: "",
  first_name: "",
  lastName: "",
  age: "",
  sex: "",
  nation: "",
  isLoading: false,
  logo: "",
  package_type: "",
  packaging_size: "",
  recommended_storage: "",
  unit: "",
  updated_at: "",
  vender: "",
  UpdatethaliStatus: "idle",
};

export const Thalireg = createAsyncThunk("Thali_reg", async () => {
  const thaliregid = localStorage.getItem("access_id");

  return await fetch(fmb_schedule_url + `admin/schedule-list-new/${thaliregid}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
    body: JSON.stringify(),
  }).then((res) => {
    return res.json();
  });
});

export const Loginuser = createAsyncThunk("loginuser", async (values) => {
  return await fetch(user_management + "admin/login", {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
    body: JSON.stringify(values),
  }).then((res) => {
    return res.json();
  });
});
export const createCalender = createAsyncThunk(
  "createCalender",
  async (values) => {
    try {
      const response = await fetch(fmb_schedule_url + "admin/thali/create", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data.message, "API response");
        // Perform any necessary actions with the response data
        toast.success(data.message);
        return data;
      } else {
        const errorData = await response.json();
        toast.error(errorData.message);
        throw new Error(errorData.message);
      }
    } catch (error) {
      console.error("API error:", error);
      toast.error("An error occurred while creating the Thali");
      throw error;
    }
  }
);

export const createRegisterthali = createAsyncThunk(
  "createRegisteruse",
  async (values) => {
    return await fetch(fmb_schedule_url + "admin/schedule-register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(values),
    }).then((res) => {
      if (res.status == 200) {
        // toast.success("Thali  successfully Update");
        console.log(res, "first response");
        return res.json();
      } else {
        toast.error(res.data.message);
      }
    });
  }
);

export const createRegisteruse = createAsyncThunk(
  "createRegisteruse",
  async (values) => {
    return await fetch(fmb_schedule_url + "admin/schedule-register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(values),
    }).then((res) => {
      if (res.status == 200) {
        toast.success("Thali  successfully Update");
        console.log(res, "first response");
        return res.json();
      } else {
        toast.error(res.data.message);
      }
    });
  }
);

export const signIn = createAsyncThunk("signin", async (values) => {
  return await fetch(api_url + "admin/registration", {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: localStorage.getItem("access_token"),
    },
    body: JSON.stringify(values),
  }).then((res) => {
    return res.json();
  });
});

export const Updatethali = createAsyncThunk(
  "thali/update",
  async ({ id, values }) => {
    try {
      const response = await fetch(
        fmb_schedule_url + `admin/thali/update/${id}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("access_token"),
          },
          body: JSON.stringify(values),
        }
      );

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Update failed");
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

export const getIngredients = createAsyncThunk("get_Ingredients", async () => {
  return await fetch(fmb_food_items_ingredients + "admin/ingredients-get", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
  }).then((res) => {
    return res.json();
  });
});

export const inventorylist = createAsyncThunk("inventory-list", async () => {
  return await fetch(Inventory_list + "admin/inventory-list", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
  }).then((res) => {
    return res.json();
  });
});

export const deleteInventory = createAsyncThunk(
  "deleteInventory",
  async (inventory_id) => {
    const requestOptions = {
      inventory_id: `${inventory_id}`,
    };
    return await fetch(
      Inventory_list + `admin/inventory-delete/${inventory_id}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },
        body: JSON.stringify(),
      }
    ).then((res) => {
      return res.json();
    });
  }
);

export const getIngredientslist = createAsyncThunk(
  "get_Ingredients_list",
  async () => {
    return await fetch(Inventory_list + "admin/inventory-list", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
    }).then((res) => {
      return res.json();
    });
  }
);

export const getAllVendor = createAsyncThunk("getAllVendor", async () => {
  return await fetch(feed_back + "admin/vendor-list", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
  }).then((res) => {
    return res.json();
  });
});

export const getAllingredients = createAsyncThunk(
  "getAllingredients",
  async () => {
    return await fetch(fmb_food_items_ingredients + "admin/ingredients-list", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
    }).then((res) => {
      return res.json();
    });
  }
);

export const getAllRegIngredients = createAsyncThunk(
  "getAllRegIngredients",
  async () => {
    return await fetch(fmb_food_items_ingredients + "admin/ingredients-reg-list", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
    }).then((res) => {
      return res.json();
    });
  }
);

export const getAllcaterer = createAsyncThunk("getAllcaterer", async () => {
  return await fetch(user_catera + "admin/caterer-get", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
  }).then((res) => {
    return res.json();
  });
});
export const updateIngredients = createAsyncThunk(
  "update_Ingredients",
  async (data) => {
    return await fetch(
      fmb_food_items_ingredients + "admin/ingredients-update",
      data,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },
        body: JSON.stringify(data),
      }
    ).then((res) => {
      return res.json();
    });
  }
);

export const getInventory = createAsyncThunk("get_Inventory", async () => {
  return await fetch(fmb_food_items_dish + "admin/dish/list", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
  }).then((res) => {
    return res.json();
  });
});

export const getDishType = createAsyncThunk("get_DishType", async () => {
  // return await fetch(menu_schedule + "admin/type/dish", {
  return await fetch(menu_schedule + "admin/type-unique-dish", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
  }).then((res) => {
    return res.json();
  });
});

export const getDishName = createAsyncThunk(
  "get_DishName",
  async (dishnamelist) => {
    const requestOptions = {
      dishnamelist: `${dishnamelist}`,
    };

    return await fetch(
      menu_schedule + `admin/dish-name-list?niyaaz_type=${dishnamelist}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },
      }
    ).then((res) => {
      return res.json();
    });
  }
);

export const getDishTypeView = createAsyncThunk(
  "view_list_dishes",
  async (DishVIEWTYPE, niyaaz_type) => {
    const requestOptions = {
      DishVIEWTYPE: `${DishVIEWTYPE}`,
    };
    return await fetch(menu_schedule + `admin/dish/name?${DishVIEWTYPE}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(),
    }).then((res) => {
      return res.json();
    });
  }
);

export const getlabelView = createAsyncThunk(
  "getlabelView",
  async (LABELVIEW) => {
    const requestOptions = {
      LABELVIEW: `${LABELVIEW}`,
    };
    return await fetch(
      Inventory_list + `admin/label-template-users-id?schedule_id=${LABELVIEW}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },
        body: JSON.stringify(),
      }
    ).then((res) => {
      return res.json();
    });
  }
);

export const getzoneView = createAsyncThunk("getzoneView", async (ZONEVIEW) => {
  const requestOptions = {
    ZONEVIEW: `${ZONEVIEW}`,
  };
  return await fetch(
    Inventory_list + `admin/zone-sheet-users-id?schedule_id=${ZONEVIEW}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(),
    }
  ).then((res) => {
    return res.json();
  });
});

export const getDishNameView = createAsyncThunk(
  "get_Dish_Name_View",
  async (DishVIEWName, niyaaz_type) => {
    return await fetch(menu_schedule + `admin/dish-type-get?${DishVIEWName}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(),
    }).then((res) => {
      return res.json();
    });
  }
);

export const getEventView = createAsyncThunk(
  "getEventView",
  async (EventLog) => {
    const requestOptions = {
      EventLog: `${EventLog}`,
    };
    return await fetch(feed_back + `admin/events-list/${EventLog}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(),
    }).then((res) => {
      return res.json();
    });
  }
);

export const dishtypelistall = createAsyncThunk(
  "dishtypelistall",
  async (values) => {
    return await fetch(menu_schedule + "admin/get-dishes-bydish-type", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(values),
    }).then((res) => {
      return res.json();
    });
  }
);

export const dishnameall = createAsyncThunk("dishnameall", async (values) => {
  return await fetch(menu_schedule + "admin/all-dish", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
    body: JSON.stringify(values),
  }).then((res) => {
    return res.json();
  });
});

export const Inventorylist = createAsyncThunk(
  "Inventorylist",
  async (values) => {
    return await fetch(Inventory_list + "admin/inventory", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(values),
    }).then((res) => {
      return res.json();
    });
  }
);

export const Reportlist = createAsyncThunk("Reportlist", async () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const todayDate = `${year}-${month}-${day}`;

  // Calculate the start date of the current week (Sunday)
  const startDate = new Date(today);
  startDate.setDate(startDate.getDate() - today.getDay());

  // Calculate the end date of the current week (Saturday)
  const endDate = new Date(today);
  endDate.setDate(endDate.getDate() + (6 - today.getDay()));

  const startYear = startDate.getFullYear();
  const startMonth = String(startDate.getMonth() + 1).padStart(2, "0");
  const startDay = String(startDate.getDate()).padStart(2, "0");
  const endYear = endDate.getFullYear();
  const endMonth = String(endDate.getMonth() + 1).padStart(2, "0");
  const endDay = String(endDate.getDate()).padStart(2, "0");

  const startOfWeek = `${startYear}-${startMonth}-${startDay}`;
  const endOfWeek = `${endYear}-${endMonth}-${endDay}`;

  const requestPayload = {
    start_date: startOfWeek,
    end_date: endOfWeek,
  };

  return await fetch(procurment_url + "admin/user-report", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
    body: JSON.stringify(requestPayload),
  }).then((res) => {
    return res.json();
  });
});

// export const getdishview = createAsyncThunk('dish_dishes', async (dishViewid) => {
//   const requestOptions = {
//     dishViewid: `${dishViewid}`
//   };
//   return await fetch(menu_schedule + `admin/dish/view2?dish_id=${dishViewid}`, {
//     method: 'GET',
//     headers: {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json',
//       'Authorization': localStorage.getItem("access_token"),
//     },
//     body: JSON.stringify()

//   }).then(res => {
//     return res.json()
//   });
// })

export const getThaliview = createAsyncThunk(
  "Thali_dishes",
  async (thaliViewid) => {
    const requestOptions = {
      thaliViewid: `${thaliViewid}`,
    };
    return await fetch(procurment_url + `admin/thali/${thaliViewid}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(),
    }).then((res) => {
      return res.json();
    });
  }
);

// export const getThaliScheduleview = createAsyncThunk('getThaliScheduleview', async (ThaliScheduleviewid) => {
//   const requestOptions = {
//     ThaliScheduleviewid: `${ThaliScheduleviewid}`
//   };
//   return await fetch(api_url + `admin/thaali/date/user-list?date1=${ThaliScheduleviewid}`, {
//     method: 'GET',
//     headers: {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json',
//       'Authorization': localStorage.getItem("access_token"),
//     },
//     body: JSON.stringify()

//   }).then(res => {
//     return res.json()
//   });
// })

export const getTotalIngredients = createAsyncThunk(
  "getTotalIngredients",
  async (TotalIngredientsid) => {
    const requestOptions = {
      TotalIngredientsid: `${TotalIngredientsid}`,
    };
    return await fetch(
      Inventory_list +
        `admin/thaali/date/ingredient-list?date=${TotalIngredientsid}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },
        body: JSON.stringify(),
      }
    ).then((res) => {
      return res.json();
    });
  }
);

export const createVendor = createAsyncThunk(
  "create_vendor",
  async (values) => {
    return await fetch(feed_back + "admin/vendor-create", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(values),
    }).then((res) => {
      return res.json();
    });
  }
);
export const updateVendor = createAsyncThunk(
  "update_vendor",
  async (values) => {
    return await fetch(feed_back + "admin/vendor-update", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(values),
    }).then((res) => {
      return res.json();
    });
  }
);
export const getVendorList = createAsyncThunk("getVendor_List", async () => {
  return await fetch(feed_back + "admin/vendor-get", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
  }).then((res) => {
    return res.json();
  });
});
export const getThaliList = createAsyncThunk("getThaliList", async () => {
  return await fetch(fmb_food_items_dish + "admin/dish/list", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
  }).then((res) => {
    return res.json();
  });
});
export const getUnitList = createAsyncThunk("getUnitList", async () => {
  return await fetch(user_management + "admin/package/unit-list", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
  }).then((res) => {
    return res.json();
  });
});
export const getTypeList = createAsyncThunk("getTypeList", async () => {
  return await fetch(user_management + "admin/package/type-list", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
  }).then((res) => {
    return res.json();
  });
});

export const getPortionList = createAsyncThunk("get_portion_List", async () => {
  return await fetch(user_catera + "admin/portion-list", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
  }).then((res) => {
    return res.json();
  });
});

export const getZoneList = createAsyncThunk("get_Zone_List", async () => {
  return await fetch(user_catera + "admin/zone-list", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
  }).then((res) => {
    return res.json();
  });
});

export const getCatererList = createAsyncThunk("getCaterer_List", async () => {
  return await fetch(user_catera + "admin/caterer-list", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
  }).then((res) => {
    return res.json();
  });
});

export const getThalilist = createAsyncThunk("getThali_List", async () => {
  return await fetch(fmb_schedule_url + "admin/thali-list", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
  }).then((res) => {
    return res.json();
  });
});

export const createcaterer = createAsyncThunk(
  "create_caterer",
  async (values) => {
    return await fetch(user_catera + "admin/caterer-create", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(values),
    }).then((res) => {
      return res.json();
    });
  }
);

export const createUser = createAsyncThunk("create_user", async (values) => {
  return await fetch(api_url + "admin/user-create", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
    body: JSON.stringify(values),
  }).then((res) => {
    return res.json();
  });
});

export const updatecaterer = createAsyncThunk(
  "update_caterer",
  async (values) => {
    return await fetch(user_catera + "admin/caterer-update", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(values),
    }).then((res) => {
      return res.json();
    });
  }
);

export const pickuptypeuser = createAsyncThunk(
  "pickuptypeuser",
  async (values) => {
    return await fetch(fmb_schedule_url + "admin/update/pickup-type", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(values),
    }).then((res) => {
      return res.json();
    });
  }
);

export const updateDish = createAsyncThunk("update_dish", async (values) => {
  return await fetch(fmb_food_items_dish + "admin/dish/update", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
    body: JSON.stringify(values),
  }).then((res) => {
    return res.json();
  });
});
export const deleteDishes = createAsyncThunk(
  "delete_dishes",
  async (dish_id) => {
    const requestOptions = {
      dish_id: `${dish_id}`,
    };
    return await fetch(fmb_food_items_dish + `admin/dish/delete/${dish_id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(),
    }).then((res) => {
      return res.json();
    });
  }
);

export const deletethali = createAsyncThunk("deletethali", async (thali_id) => {
  const requestOptions = {
    thali_id: `${thali_id}`,
    user_id: `${access_id}`,
  };
  return await fetch(
    
    fmb_schedule_url + `admin/deleteThaaliSchedule/${thali_id}&user_id=${access_id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestOptions),
    }
  ).then((res) => {
    return res.json();
  });
});

export const RegisteruserData = createAsyncThunk(
  "Deactivecaterer",
  async (Registeruser_id) => {
    // return await fetch(feed_back + `admin/view/all-register-user/${Registeruser_id?._id}`, {
    return await fetch(
      feed_back + `admin/view/all-register-user-menu/${Registeruser_id?._id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },
        body: JSON.stringify(),
      }
    ).then((res) => {
      return res.json();
    });
  }
);

export const deleteIngredients = createAsyncThunk(
  "delete_ingredients",
  async (ingredients_id) => {
    const requestOptions = {
      ingredients_id: `${ingredients_id}`,
    };
    return await fetch(
      fmb_food_items_ingredients + `admin/ingredients-delete/${ingredients_id}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },

        body: JSON.stringify(),
      }
    ).then((res) => {
      return res.json();
    });
  }
);
// export const getCityList = createAsyncThunk("city_list", async () => {
//   return await fetch(api_url + "admin/city/get", {
//     method: "GET",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//       Authorization: localStorage.getItem("access_token"),
//     },
//   }).then((res) => {
//     return res.json();
//   });
// });

export const deleteVendor = createAsyncThunk(
  "delete_Vendor",
  async (vendor_id) => {
    const requestOptions = {
      vendor_id: `${vendor_id}`,
    };
    return await fetch(feed_back + `admin/vendor-delete/${vendor_id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },

      body: JSON.stringify(),
    }).then((res) => {
      return res.json();
    });
  }
);
export const fetchUserData = createAsyncThunk("getUser_List", async (filters) => {
  const queryParams = new URLSearchParams();

  // Add filters to query
  if (filters.active) queryParams.append("active", "true");
  if (filters.inactive) queryParams.append("inactive", "true");
  if (filters.nonits) queryParams.append("nonits", "true");
  if (filters.khidmatguzar) queryParams.append("khidmatguzar", "true");
  if (filters.page) queryParams.append("page", filters.page)
  return await fetch(user_management + "admin/get-user" + `?${queryParams.toString()}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
  }).then((res) => {
    return res.json();
  });
});

export const fetchNotesUserData = createAsyncThunk("getUser_List", async () => {
  return await fetch(user_management + "admin/get-notes-user", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
  }).then((res) => {
    return res.json();
  });
});

export const procurmentlist = createAsyncThunk("procurment_list", async () => {
  return await fetch(procurment_url + "admin/procurment-list", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
  }).then((res) => {
    return res.json();
  });
});

export const fetchUsersData = createAsyncThunk("getUser_List", async () => {
  return await fetch(user_management + "admin/get-users-data", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
  }).then((res) => {
    return res.json();
  });
});

export const fetchAllUserData = createAsyncThunk(
  "getAllUser_List",
  async () => {
    return await fetch(user_management + "admin/get-all-user", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
    }).then((res) => {
      return res.json();
    });
  }
);

export const Thalischedule = createAsyncThunk("getThalischedule", async () => {
  return await fetch(fmb_schedule_url + "admin/schedule-list-detail", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    },
  }).then((res) => {
    return res.json();
  });
});

export const getFeedbackdata = createAsyncThunk(
  "getfeedback_List",
  async () => {
    return await fetch(feed_back + "admin/feedback-get", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
    }).then((res) => {
      return res.json();
    });
  }
);
export const getRegisterdatalist = createAsyncThunk(
  "getRegisterdatalist",
  async () => {
    return await fetch(feed_back + "admin/registered-users", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
    }).then((res) => {
      return res.json();
    });
  }
);
export const authReducer = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Loginuser.pending, (state) => {
      return { ...state, Loginuser: "pending" };
    });
    builder.addCase(Loginuser.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          token: action.payload,
        };
      } else return state;
    });
    builder.addCase(Loginuser.rejected, (state) => {
      return { ...state, Loginuser: "failed" };
    });

    builder.addCase(signIn.pending, (state) => {
      return { ...state, signin: "pending" };
    });
    builder.addCase(signIn.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          token: action.payload,
        };
      } else return state;
    });
    builder.addCase(signIn.rejected, (state) => {
      return { ...state, signin: "failed" };
    });

    builder.addCase(createCalender.pending, (state) => {
      return { ...state, createCalender: "pending" };
    });
    builder.addCase(createCalender.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          token: action.payload,
        };
      } else return state;
    });
    builder.addCase(createCalender.rejected, (state) => {
      return { ...state, createCalender: "failed" };
    });

    builder
      .addCase(Updatethali.pending, (state) => {
        state.UpdatethaliStatus = "pending";
      })
      .addCase(Updatethali.fulfilled, (state, action) => {
        state.UpdatethaliStatus = "fulfilled";
        // Handle the action.payload data if needed
      })
      .addCase(Updatethali.rejected, (state) => {
        state.UpdatethaliStatus = "rejected";
      });

    builder.addCase(createRegisteruse.pending, (state) => {
      return { ...state, createRegisteruse: "pending" };
    });
    builder.addCase(createRegisteruse.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          token: action.payload,
        };
      } else return state;
    });
    builder.addCase(createRegisteruse.rejected, (state) => {
      return { ...state, createRegisteruse: "failed" };
    });

    builder.addCase(createVendor.pending, (state) => {
      return { ...state, createVendor: "pending" };
    });
    builder.addCase(createVendor.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          token: action.payload,
        };
      } else return state;
    });
    builder.addCase(createVendor.rejected, (state) => {
      return { ...state, createVendor: "failed" };
    });

    builder.addCase(updateVendor.pending, (state) => {
      return { ...state, updateVendor: "pending" };
    });
    builder.addCase(updateVendor.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          token: action.payload,
        };
      } else return state;
    });
    builder.addCase(updateVendor.rejected, (state) => {
      return { ...state, updateVendor: "failed" };
    });

    builder.addCase(createcaterer.pending, (state) => {
      return { ...state, createcaterer: "pending" };
    });
    builder.addCase(createcaterer.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          token: action.payload,
        };
      } else return state;
    });
    builder.addCase(createcaterer.rejected, (state) => {
      return { ...state, createcaterer: "failed" };
    });

    builder.addCase(updateDish.pending, (state) => {
      return { ...state, updateDish: "pending" };
    });
    builder.addCase(updateDish.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          token: action.payload,
        };
      } else return state;
    });
    builder.addCase(updateDish.rejected, (state) => {
      return { ...state, updateDish: "failed" };
    });
  },
});

export default authReducer.reducer;
