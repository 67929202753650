import React, { useEffect, useState } from "react";
import userprofilelist from "../../../img/userprofilelist.png";
import remove from "../../../img/remove.png";
import editicon from "../../../img/editicon.png";
import Header from "../Header/Header";
import Modal from "react-modal";
import DateRangePicker from "react-bootstrap-daterangepicker";
import calender from "../../../img/Icon/calender.svg";
import Select from "react-select";
import { Link, useLocation } from "react-router-dom";
import Sidebarmenu from "../../Sidebar/Sidebarmenu";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import user_icon from "../../../img/Icon/user_icon.svg";
import user_side from "../../../img/Icon/Userside.svg";
import email_icon from "../../../img/Icon/email.svg";
import phone_icon from "../../../img/Icon/phone.svg";
import ven from "../../../img/ven.png";

import { CSVLink } from "react-csv";
import "../Dashboard.css";
import {
  getRegisterdatalist,
  RegisteruserData,
  createRegisteruse,
  getZoneList,
  getPortionList,
  api_url,
  fetchAllUserData,
  feed_back,
  fmb_schedule_url,
} from "../../../Redux/Reducer/AuthReducer";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import loadergif from "../../../img/landing/loader123.gif";
import "react-datepicker/dist/react-datepicker.css";

export default function Registeruser() {
  var token = localStorage.getItem("access_token");
  const dispatch = useDispatch();
  const location = useLocation();

  const thalicountData = location.state?.thalicount || [];
  const ingredientscount = location?.state?.ingredientscountlist?.data || [];
  const ingredientstotalPages =
    location?.state?.ingredientscountlist?.totalPages || [];
  const ingredientstotalall = location?.state?.allcount || [];
  const thaliScheduleID = location?.state?.scheduleId || [];
  const ingredientTodaydate = location?.state?.Todaydate || [];
  const thaliMenuSummary = location?.state?.menuSummary || [];

  const [ragister, setRagister] = useState([]);
  const [menuSummary, setMenuSummary] = useState(thaliMenuSummary);
  const [Storeduser, setStoreduser] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [ragisterView, setRagisterView] = useState([]);
  const [sortColumn, setSortColumn] = useState("created_at");
  const [sortOrder, setSortOrder] = useState("asc");
  const [zoneid, setZoneid] = useState("");
  const formattedStartDate = startDate.toISOString()?.split("T")[0];
  const [newDate, setNewDate] = useState(new Date());
  const [userdataview, setUserDataView] = useState([]);
  const [userdataviewits, setUserDataViewIts] = useState([]);
  const [userdatalist, setUserDataList] = useState([]);
  const [newuserdataview, setNewDataView] = useState([]);
  const [ItsId, setItsId] = useState("");
  const [lastname, setLastName] = useState("");
  const [source, setSource] = useState("");
  const [firstname, setFirstName] = useState("");
  const [roticheck, setRotiCheck] = useState("1");
  const [ricecheck, setRiceCheck] = useState("1");
  const [userid, setUserID] = useState("");
  const [schedule_id, setScheduleId] = useState("");
  const [userName, setUserName] = useState("");
  const [addzone, setAddZone] = useState("");
  const [Portionzone, setPortionZone] = useState("");
  const [RegiThali, setRegiThali] = useState("");
  const [totalcount, setTotalCount] = useState("");
  const [ingredientsall, setingredientsall] = useState("");
  const [loading, setLoading] = useState(true);
  const [unregisteruser, setUnregisteruser] = useState("");

  const [scheduleDay, setScheduleDay] = useState(new Date().toISOString());


  const sixpeopleall = ingredientsall
    ? menuSummary[0]?.five_six_THAALIS
    : thaliMenuSummary[0]?.five_six_THAALIS;
  const fivepeopleall = ingredientsall
    ? menuSummary[0]?.three_four_THAALIS
    : thaliMenuSummary[0]?.three_four_THAALIS;
  const threepeopleall = ingredientsall
    ? menuSummary[0]?.one_two_THAALIS
    : thaliMenuSummary[0]?.one_two_THAALIS;
  const xsmallall = ingredientsall
    ? menuSummary[0]?.x_small
    : thaliMenuSummary[0]?.x_small;

  console.log("thaliMenuSummary", thaliMenuSummary);
  const [sixpeople, setSixPeople] = useState(sixpeopleall);
  const [fivepeople, setFivePeople] = useState(fivepeopleall);
  const [threepeople, setThreePeople] = useState(threepeopleall);
  const [xsmall, setXSmall] = useState(xsmallall);

  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero if necessary
  const day = today.getDate().toString().padStart(2, "0"); // Add leading zero if necessary

  const formattedall = `${year}-${month}-${day}`;
  const [calenderdate, setCalenderDate] = useState(
    formattedall ? formattedall : ingredientTodaydate
  );

  function convertDateForIos(date) {
    var arr = date?.split(/[- :]/);
    date = new Date(arr[2], arr[0]-1, arr[1]);
    return date;
}

  const getUserData = () => {
    setLoading(true);
    dispatch(fetchAllUserData())
      .then((res) => {
        if (res?.payload?.status === true) {
          setUserDataList(res?.payload?.data);
          setUserDataViewIts(res?.payload?.data);
        } else {
          toast.error(res?.payload?.message);
        }
      })
      .finally(() => {
        setLoading(false); // Set loading to false when data fetching is complete
      });
  };

  const userlistall = userdatalist.map((item) => {
    return {
      label: item.first_name,
      value: item.id,
      its_id: item.its_id,
      // phone: item.phone,
      lname: item.last_name,
      Fname: item.first_name,
    };
  });

  const userlistits = userdataviewits.map((item) => {
    return {
      label: item.its_id,
      value: item.id,
      its_id: item.its_id,
      // phone: item.phone,
      lname: item.last_name,
      Fname: item.first_name,
    };
  });

  console.log(
    newuserdataview,
    "userdatalist",
    newuserdataview,
    "userdataviewits"
  );

  useEffect(() => {
        
    setLastName(userdataview?.lname);
    setFirstName(userdataview?.Fname);
    setItsId({ label: userdataview.its_id, value: userdataview.its_id });
    setUserID(userdataview?.value);
    setSource(userdataview?.value);
  }, [userdataview]);

  useEffect(() => {

    setLastName(newuserdataview?.lname);
    setFirstName(newuserdataview?.Fname);
    setUserName({
      label: newuserdataview.Fname,
      value: newuserdataview?.Fname,
    });
    setUserID(newuserdataview?.value);
    setSource(newuserdataview?.value);
  }, [newuserdataview]);

  const handleSort = (columnName) => {
    if (sortColumn === columnName) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      setSortColumn(columnName);
      setSortOrder("asc");
    }
  };

  const getSortIcon = (columnName) => {
    if (sortColumn === columnName) {
      return sortOrder === "asc" ? (
        <i className="fa fa-sort-up"></i>
      ) : (
        <i className="fa fa-sort-down"></i>
      );
    }
    return null;
  };

  const sortragister = [...ragister].sort((a, b) => {
    if (sortColumn === "Name") {
      const nameA = a.first_name.toLowerCase();
      const nameB = b.first_name.toLowerCase();

      if (nameA < nameB) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (nameA > nameB) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    }
    // Handle sorting for other columns if needed
    return 0;
  });

  const handleRegisteruser = (requestData) => {
    dispatch(createRegisteruse(requestData));
  };
  const handleRotiChange = (e, index, isChecked) => {
    const { checked } = e.target;

    setRagisterView((prevuser) =>
      prevuser.map((item, i) =>
        i === index ? { ...item, want_roti: checked ? "1" : "0" } : item
      )
    );
  };
  const handleRiceChange = (e, index, isChecked) => {
    const { checked } = e.target;

    setRagisterView((prevuser) =>
      prevuser.map((item, i) =>
        i === index ? { ...item, want_rice: checked ? "1" : "0" } : item
      )
    );
  };

  const handleSourceChange = (e, index) => {
    const sourceValue = e.target.value;
    setRagisterView((prevuser) =>
      prevuser.map((item, i) =>
        i === index ? { ...item, source: sourceValue } : item
      )
    );
  };

  const handleTHALIChange = (e, index, isChecked) => {
    const { checked } = e.target;

    setRagisterView((prevuser) =>
      prevuser.map((item, i) =>
        i === index ? { ...item, is_active: checked ? true : false } : item
      )
    );
  };

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isFocused ? "#AE8340" : null,
        boxShadow: isFocused ? "none" : "none",
        color: "#333333",
      };
    },
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      maxWidth: "1500px",
      transform: "translate(-50%, -50%)",
    },
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [RemoveIsOpen, setIsRemoveOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  function RemoveModal() {
    setIsRemoveOpen(true);
  }
  function afterOpenModal() {}
  function RemoveOpenModal() {}

  function RemovecloseModal() {
    setIsRemoveOpen(false);
  }

  const getragisterdata = () => {
    console.log (
      "Reloadding..."
    )
    dispatch(getRegisterdatalist()).then((res) => {
      if (res?.payload?.status === true) {
        updateDateByDate(scheduleDay?.split("T")[0])
        setRagister(res?.payload?.data);
        setMenuSummary(res?.payload?.menu_summary);
        setingredientsall(res?.payload?.count);
        setScheduleId(res?.payload?.menu_summary[0]?.schedule_id);
        setScheduleDay(res?.payload?.menu_summary[0]?.schedule_day);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  useEffect(() => {
        if (thalicountData.length === 0) {
      getragisterdata();
    } else {
      setRagister(thalicountData);
      setScheduleId(thaliScheduleID);
    }
    zoneList();
    portionList();
    getUserData();
  }, []);

  const Registerviewdata = (id, date) => {
    setLoading(true);
    const dateall = new Date(date);
    const formattedDate = dateall.toISOString()?.split("T")[0]; // Convert date to ISO string

    const queryParams = new URLSearchParams();

    queryParams.append("id", id);
    queryParams.append("date", formattedDate);

    const data = {
      _id: id,
      date: formattedDate,
    };

    dispatch(RegisteruserData(data)).then((res) => {
      if (res?.payload?.status === true) {
        setRagisterView(res?.payload?.data);
        setLoading(false);
        openModal()
      } else {
        toast.error(res?.payload?.message);
        setLoading(false);
      }
    });
  };

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = async () => {
    const dateall = new Date(newDate);
    const formattedDate = dateall.toISOString()?.split("T")[0]; // Convert date to ISO string
    const searchURL = unregisteruser ? "admin/search-unregistered?name" : "admin/search-registered?name"

    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json", // Assuming you're sending form data
        Authorization: localStorage.getItem("access_token"),
      };

      const response = await fetch(
        feed_back + searchURL + `=${encodeURIComponent(
            searchQuery
          )}&date=${formattedDate}`,
        {
          method: "GET",
          headers: headers,
        }
      );
      if (response.ok) {
        const data = await response.json();
        console.log("data:", data); // Check the structure of the received data
        if (data.data && Array.isArray(data.data)) {
          setRagister(data.data);
          // Set the total number of pages
          console.log("Invali:", data.data);
        } else {
          console.log("Invalid data format:", data.data);
          setRagister(data);
        }
      } else {
        console.log("Error: ", response.status);
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    if (searchQuery) {
      handleSearch();
    }
  }, [searchQuery]);

  const [totalPages, setTotalPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 10; // Maximum number of visible pages

    const startPage = Math.max(
      1,
      currentPage - Math.floor(maxVisiblePages / 2)
    );
    const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  // const [modalIsOpen, handleSubmituser] = React.useState(false);

  const handleSubmituser = (e) => {
    e.preventDefault();
    const obj = ragisterView.map((item, i) => ({
      schedule_id: item.schedule_id,
      user_id: item.user_id,
      want_rice: item.want_rice,
      want_roti: item.want_roti,
      is_active: item.is_active ? 1 : 0,
      pickup_location_code: item.pickup_location_code,
      portion_type_code: item.portion_type_code,
      created_user: access_id,
      source: item.source ? item.source : "",
    }));

    handleRegisteruser(obj);
    getragisterdata();
    closeModal();
  };

 // Sync Count

   const updateDateByDate = (date) => {
    const requestBody = {
      date: date,
    };
    fetch(feed_back + "admin/update-date-register-user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json", // Assuming you're sending form data
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === true) {
          console.log (data?.message)
        } else {
          console.log ("Failed Update Data")
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };


  // dateapi
  const selectdate = (date) => {
    setLoading(true);
    setStartDate(date);

    const d1 = date.toLocaleString()?.split("/")[1];
    const d2 = date.toISOString()?.split("T")[0]?.split("-")[2];

    let formattedStartDate;

    if (d2.includes(d1)) {
      formattedStartDate = date.toISOString()?.split("T")[0]; // Format the selected date
    } else {
      const previousDate = new Date(date.getTime() - 86400000);
      formattedStartDate = previousDate.toISOString()?.split("T")[0]; // Format the selected date
    }

    setNewDate(formattedStartDate);

    const requestBody = {
      date: formattedStartDate,
    };

    setCalenderDate(formattedStartDate);
    fetch(feed_back + "admin/date-register-user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json", // Assuming you're sending form data
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === true) {
          updateDateByDate(formattedStartDate)
          setingredientsall(data?.count);
          setRagister(data?.data);
          setMenuSummary(data?.menu_summary);
          setScheduleId(data?.menu_summary[0]?.schedule_id);
          setScheduleDay(data?.menu_summary[0]?.schedule_day)
          setLoading(false);
        } else {
          toast.error(data?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };


  // dateapi
  const fetchUnRegisterUser = () => {
       const requestBody = {
      date: scheduleDay,
    };

    fetch(feed_back + "admin/date-unregister-user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json", // Assuming you're sending form data
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === true) {
          setingredientsall(data?.count);
          setRagister(data?.data);
          setMenuSummary(data?.menu_summary);
          setScheduleId(data?.menu_summary[0]?.schedule_id);
          setScheduleDay(data?.menu_summary[0]?.schedule_day)
          setLoading(false);
        } else {
          toast.error(data?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Export User Data
  const csvData = ragister?.map((items) => {
    return {
      itsID: items?.its_id,
      name: items?.designation+' '+items?.first_name+' '+items?.last_name,
      date: items?.schedule_day,
      portion: items?.portion,
      zone: items?.zone,
      rice: items?.want_rice == "1" ? "Yes" : items?.want_rice == "0" ? "No" : "N/A",
      roti: items?.want_roti == "1" ? "Yes" : items?.want_roti == "0" ? "No" : "N/A",
      mithas: items?.want_mithas == "1" ? "Yes" : items?.want_mithas == "0" ? "No" : "N/A",
      phone_number: items?.phone !== "NULL" ? items?.phone : ""
    };
  });

  const csvHeaders = [
    { label: "Date", key: "date" },
    { label: "ITS ID", key: "itsID" },
    { label: "Name", key: "name" },
    { label: "Phone #", key: "phone_number" },
    { label: "Portion", key: "portion" },
    { label: "Zone", key: "zone" },
    { label: "Rice", key: "rice" },
    { label: "Roti", key: "roti" },
    { label: "Mithas", key: "mithas" }

  ];



  const [zonedata, setZonedata] = useState([]);
  const zoneList = () => {
    dispatch(getZoneList()).then((res) => {
      console.log(res, "res");
      if (res?.payload?.status === true) {
        setZonedata(res?.payload?.data);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };
  let zoneListData = [];
  zoneListData = zonedata.map((item) => ({
    label: item.pickup_location_name,
    value: item.pickup_location_code,
  }));

  const [portionlist, setPortionList] = useState([]);
  const portionList = () => {
    dispatch(getPortionList()).then((res) => {
      if (res?.payload?.status === true) {
        setPortionList(res?.payload?.data);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  let portionListData = [];
  portionListData = portionlist.map((item) => ({
    label: item.portion_type_name,
    value: item.portion_type_code,
  }));

  let portionListDataall = [];
  portionListDataall = portionlist.map((item) => ({
    label: item.portion_type_name,
    value: item.portion_type_code,
  }));
  let zoneListDataall = [];
  zoneListDataall = zonedata.map((item) => ({
    label: item.pickup_location_name,
    value: item.pickup_location_code,
  }));

  const handlezoneIdChange = (index, label) => {
    const updatedzone = ragisterView.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          pickup_location_code: label,
        };
      }
      return item;
    });

    setRagisterView(updatedzone);
  };

  const handlezoneIdChange2 = (index, label) => {
    const updatedzone = ragisterView.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          portion_type_code: label,
        };
      }
      return item;
    });

    setRagisterView(updatedzone);
  };

  const handleToggleChange = (e) => {
    const newValue = roticheck === "1" ? "0" : "1";
    setRotiCheck(newValue);
  };
  const handleToggleChangerice = (e) => {
    const newValue = ricecheck == "1" ? "0" : "1";
    setRiceCheck(newValue);
  };

  const handleToggleRegisterThali = (e) => {
    const newValue = RegiThali == "1" ? "0" : "1";
    setRegiThali(newValue);
  };

  const access_id = localStorage.getItem("access_id");
  const requestBodyArray = [];

  const handleAddUser = (e) => {
    e.preventDefault();

    if (schedule_id !== "" || userid !== "") {
      const requestBody = {
        schedule_id: schedule_id,
        user_id: userid,
        want_rice: ricecheck,
        want_roti: roticheck,
        portion_type_code: Portionzone.value,
        pickup_location_code: addzone.value,
        created_user: access_id,
        is_active: 1,
        source: source ? source : "",
      };

      setLastName("");
      setFirstName("");
      setItsId("");
      setUserName("");
      setSearchQuery("");
      setSource("");
      requestBodyArray.push(requestBody);
      getRegisterchange(requestBodyArray); // Uncomment this line if needed.
    } else {
      toast.error("Register User information cannot be blank");
    }
  };

  const getRegisterchange = (requestBodyArray) => {
    setLoading(true);
    fetch(fmb_schedule_url + "admin/schedule-register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json", // Assuming you're sending form data
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestBodyArray),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === true) {
          toast.success(data?.message);
          getragisterdata();
          setLoading(false);
        } else {
          toast.error(data?.message);
          console.log("error ", data);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when data fetching is complete
      });
  };


  const handleToggleChange2 = (e) => {
    const newValue = unregisteruser === "1" ? "" : "1";
    setUnregisteruser(newValue);
    newValue ? fetchUnRegisterUser() : selectdate(startDate)
  };

  /**
   * Created by jjansen on 09-Mar-15 4:56 PM.
   * stackoverflow
   */
  var dateName = function (yyyy, mm, dd) {
    console.log ("yyyy", yyyy, mm, dd)
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var dayNames = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    var today = new Date(yyyy, mm - 1, dd);
    console.log ("Todays Date", yyyy, mm, dd)
    var dd = today.getDate();
    var dayNameIndex = (today.getDay() - 1) == -1 ? 6 : today.getDay() - 1
    var dayname = dayNames[dayNameIndex];
    var mm = monthNames[today.getMonth()];
    var yyyy = today.getFullYear();
    var fullDate = dayname + ", " + mm + " " + dd + ", " + yyyy;
    return fullDate;
  };

  const totalragister = ingredientsall ? ingredientsall : ingredientstotalall 
  const NewToday = "" ? "" : ingredientTodaydate;
  const inputDate = calenderdate;
  const date = new Date(inputDate);
  const options = {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    weekday: "long",
  };
  const formattedCurrentDate = scheduleDay ? scheduleDay?.split("T")[0] : date.toISOString().split("T")[0]; // Convert date to ISO string
  const dayInformation = dateName(formattedCurrentDate?.split("-")[0], formattedCurrentDate?.split("-")[1], formattedCurrentDate?.split("-")[2])

  const registerLabel = unregisteruser ? "Unregistered" : "Registered";
  const csvFilename = unregisteruser ? "Unregistered User Data_"+formattedCurrentDate : "Registered User Data_"+formattedCurrentDate;

  const state = {
    series: [50, 50],

    options: {
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      fill: {
        colors: ["#e5e5e5", "#6b74e6"],
      },
    },
  };
  return (
    <div>
      <Header />
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarmenu />
        <section className="main_section">
          <div className="custam">
            <div className="main_section2">
              <div className="ExportInventory thali_section Thali_page">
                <div className="container-fluid">
                  <div className="thili_div">
                    <div className="Back-page">
                      <h1 className="title_heading">
                      {registerLabel} Users ({totalragister}) &nbsp; &nbsp; &nbsp;
                        &nbsp;
                        <span className="Line_div">|</span>
                        <span className="Date_list">{dayInformation ? dayInformation : "" }</span>{" "}
                      </h1>{" "}
                    </div>
                    { ragister?.length > 0 &&
                        <div className="Thali_page_btn AddStandardInventory AddUnRegisteredText">
                          <img
                              src={user_side}
                              className="img-fluid"
                            />
                            &nbsp; &nbsp;
                          <label for="Standard">
                            Unregistered Users &nbsp; &nbsp;
                          </label>
                        <div className="onoffswitch">
                          <input
                            type="checkbox"
                            name="onoffswitch"
                            className="onoffswitch-checkbox"
                            id="rice_check"
                            checked={unregisteruser == "1"}
                            onChange={handleToggleChange2}
                          />
                          <label className="onoffswitch-label" for="rice_check">
                            <span className="onoffswitch-inner"></span>
                            <span className="onoffswitch-switch"></span>
                          </label>
                        </div>
                      </div>
                    }
                    <div className="Thali_page_btn New_section">
                      <div className="Date_ragisterdata calender_view">
                        <div className="calender-div">
                          <DatePicker
                            selected={startDate}
                            onChange={selectdate}
                          />
                        </div>
                        <img src={calender} className="img-fluid" />
                      </div>
                      <span class="Line_div">|</span>
                      <div className="Form_box">
                        {/* <button
                          className="btn thali_btn"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight"
                        >
                          Register User{" "}
                        </button> */}
                        {/* <Link to="#">
                          // <button className="btn thali_btn" onClick={() => getragisterdata()} >Load Data</button>
                        </Link> */}
                      </div>
                      <div className="Form_box">
                      <button className="btn thali_btn csvbtn">
                      <CSVLink
                            data={csvData}
                            headers={csvHeaders}
                            filename={csvFilename}
                          >
                            Export Data
                          </CSVLink>
                      </button>
                      </div>
                    </div>
                  </div>

                  {/* <div className="Thali-count-by-portion">
                    <div></div>
                    <div className="thaliportion">
                      <h1>Thali count by portion</h1>
                      <ul>
                        <li>
                          <h5>
                            {" "}
                            <span> </span>5-6 People :{" "}
                            {sixpeopleall ? sixpeopleall : ""} Thali
                          </h5>
                        </li>
                        <li>
                          <h5>
                            {" "}
                            <span> </span>3-4 People:{" "}
                            {fivepeopleall ? fivepeopleall : ""} Thali
                          </h5>
                        </li>
                        <li>
                          <h5>
                            <span> </span>1-2 People:{" "}
                            {threepeopleall ? threepeopleall : ""} Thali
                          </h5>
                        </li>
                        <li>
                          <h5>
                            <span></span> X Small: {xsmallall ? xsmallall : ""}{" "}
                            Thali
                          </h5>
                        </li>
                      </ul>
                    </div>
                  </div> */}

                {ragister?.length == 0 ? (
                              <div className="No_Inventory">
                              <h1 >No Thaali Available Today</h1>
                              </div>
                          ):
                  (
                  <div className="thali_table_section  TotalIngredientstable">
                    <table className="table">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">
                            <span className="thali_table">Dishes</span>
                          </th>
                          <th scope="col">
                            <span className="thali_table">Caterers</span>
                          </th>
                          <th scope="col">
                            <span className="thali_table">fateha</span>
                          </th>
                          <th scope="col">
                            <span className="thali_table">Remarks</span>
                          </th>
                          <th scope="col">
                            <span className="thali_table">
                              Thali count by portion
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {menuSummary &&
                          menuSummary.map((item) => {
                            return (
                              <tr>
                                <th scope="row">
                                  <ul className="thali_menu_list">
                                    {item.menu &&
                                      JSON.parse(item.menu).map(
                                        (menu, index) => (
                                          <li key={index}>
                                            {menu ? menu : "NA"}
                                          </li>
                                        )
                                      )}
                                  </ul>
                                </th>
                                <th scope="row">
                                {item.jamanType == "COOKING" &&
                                  <ul className="thali_menu_list">
                                    {item.cater_name1 != null &&
                                      item.cater_name1 !== "" && (
                                        <li>
                                          &nbsp;
                                          <img
                                            src={user_icon}
                                            className="img-fluid"
                                          />
                                           &nbsp;&nbsp;{item.cater_name1}
                                        </li>
                                      )}
                                    {item.cater_name2 != null &&
                                      item.cater_name2 !== "" && (
                                        <li>
                                          <img
                                            src={user_icon}
                                            className="img-fluid"
                                          />
                                          {item.cater_name2}
                                        </li>
                                      )}
                                  {item.cater1_email &&
                                  item.cater1_email !== "null" && (
                                    <>
                                      <li>
                                        <img
                                          src={email_icon}
                                          className="img-fluid"
                                          width="22px"
                                          height="22px"
                                        />
                                        &nbsp; 
                                        {item.cater1_email
                                          ? item.cater1_email
                                          : "NA"}
                                      </li>
                                    </>
                                  )}
                                  {item.cater1_phone_number &&
                                  item.cater1_phone_number !== "null" && (
                                  <>
                                  <li>
                                       &nbsp;
                                        <img
                                          src={phone_icon}
                                          className="img-fluid"
                                          width="18px"
                                          height="18px"
                                        />
                                        &nbsp;
                                        {item.cater1_phone_number
                                          ? item.cater1_phone_number
                                          : "NA"}
                                      </li>
                                    </>
                                  )}
                                </ul>
                                }
                                {item.jamanType == "ORDERING" &&
                                    <ul className="thali_menu_list">
                                        <li>
                                          {item.jamanType == "ORDERING" && (
                                        <span className="Draft_ordering_event">
                                          {item.jamanType == "ORDERING" ? "ORDERING" : ""}
                                        </span>
                                      )}
                                        </li>
                                        <>
                                          <li>
                                            <img
                                              src={ven}
                                              className="img-fluid"
                                              width="16px"
                                              height="16px"
                                            />
                                            &nbsp; &nbsp; 
                                            {item.order_by
                                              ? item.order_by
                                              : "-"}
                                          </li>
                                        </>
                                    </ul>
                                }
                                </th>
                                <th scope="row">
                                  <ul className="thali_menu_list">
                                    <li>
                                      {item.fateha_name
                                        ? item.fateha_name
                                        : "-"}{" "}
                                    </li>
                                  </ul>
                                </th>
                                <th scope="row">
                                  <ul className="thali_menu_list">
                                    <li>{item.notes ? item.notes : "-"}</li>
                                  </ul>
                                </th>
                                <th scope="row">
                                {!unregisteruser &&
                                  <ul className="thali_menu_list totalspan">
                                  <li>
                                      <span> </span>1-2 People:{" "}
                                      {threepeopleall ? threepeopleall : ""}{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <span> </span>3-4 People:{" "}
                                      {fivepeopleall ? fivepeopleall : ""}
                                    </li>
                                    <li>
                                      {" "}
                                      <span> </span>5-6 People :{" "}
                                      {sixpeopleall ? sixpeopleall : ""}
                                    </li>
                                    <li>
                                      <span></span> X Small:{" "}
                                      {xsmallall ? xsmallall : ""}
                                    </li>
                                  </ul>
                                 }
                                </th>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  )}
                </div>
                <div className="thali_count_list">
                  <div className="form-group has-search">
                    <span className="fa fa-search form-control-feedback"></span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>
                {loading ? (
                  // Render a loading indicator while data is being fetched
                  <div className="Loading-img">
                    <img src={loadergif} className="" />
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="">
                  <div className="thali_table_section">
                    <table className="table table-bordered table-responsive">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">
                            <span className="thali_table">Day</span>
                          </th>
                          <th scope="col">
                            <span className="thali_table">ITS Id </span>
                          </th>
                          <th scope="col" onClick={() => handleSort("Name")}>
                            <span className="thali_table">
                              Name &nbsp; &nbsp; &nbsp;
                              {getSortIcon("Name")}
                            </span>
                          </th>
                          <th scope="col">
                            <span className="thali_table">Phone #</span>
                          </th>
                          <th scope="col">
                            <span className="thali_table">Zone</span>
                          </th>
                          <th scope="col">
                            <span className="thali_table">Portion</span>
                          </th>
                          <th scope="col">
                            <span className="thali_table">Rice</span>
                          </th>
                          <th scope="col">
                            <span className="thali_table">Roti</span>
                          </th>
                          { (menuSummary[0]?.schedule_type == "FMBTHALI" && menuSummary[0]?.is_niyaaz == "1") &&
                          <th scope="col">
                            <span className="thali_table">Mithas</span>
                          </th>
                          }
                          <th scope="col">
                            <span className="thali_table">REGISTER THAALI</span>
                          </th>
                          {/* <th scope="col">
                            <span className="thali_table">Action</span>
                          </th> */}
                          {/* <th scope="col"><span className='thali_table'>Current Qty/Unit</span></th>
                                          <th scope="col"><span className='thali_table'>Order PKG COUNT/TYPE</span></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {sortragister &&
                          sortragister.map((item) => {
                            // const date = new Date(item.schedule_day);
                            const date = convertDateForIos(item.schedule_day)
                            const day = date.toLocaleString("en-US", {
                              weekday: "long",
                            });
                            const month = date.toLocaleString("en-US", {
                              month: "long",
                            });
                            const numericDate = date.getDate();
                            const twoDigitYear = date.getFullYear();
                            return (
                              <tr key={item.id}>
                                <th scope="row">
                                  <ul className="thali_menu_list">
                                    <li>
                                      {month ? month + "  " : ""}
                                      {numericDate ? numericDate : "-"}{" "}
                                    </li>
                                  </ul>
                                </th>
                                <th scope="row">
                                  <ul className="thali_menu_list">
                                    <li>{item.its_id ? item.its_id : "-"}</li>
                                  </ul>
                                </th>
                                <th scope="row">
                                  <ul className="thali_menu_list">
                                    <li>
                                      {item.designation ? item.designation+" " : ""}
                                      {item.first_name ? item.first_name : "-"}{" "}
                                      {item.last_name ? item.last_name : "-"}
                                    </li>
                                  </ul>
                                </th>
                                <th scope="row">
                                  <ul className="thali_menu_list">
                                    <li>
                                      {item.phone !== "NULL" ? item.phone : "-"}
                                    </li>
                                  </ul>
                                </th>
                                <th scope="row">
                                  <ul className="thali_menu_list">
                                    <li>{item.zone ? item.zone : "-"}</li>
                                  </ul>
                                </th>
                                <th scope="row">
                                  <ul className="thali_menu_list">
                                    <li>{item.portion ? item.portion : "-"}</li>
                                  </ul>
                                </th>
                                <th scope="row">
                                  <ul className="thali_menu_list">
                                    <li>
                                      {" "}
                                      <div className="Register_user_input">
                                        {item.want_rice == "-1" ? (
                                          <>-</>
                                        ) : (
                                          <>
                                            <div className="form-check form-switch">
                                              <input
                                                disabled
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="rice_check"
                                                checked={item.want_rice}
                                              />
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </li>
                                  </ul>
                                </th>
                                <th scope="row">
                                  <ul className="thali_menu_list">
                                    <li>
                                      <div className="Register_user_input">
                                        {item.want_roti == "-1" ? (
                                          <>-</>
                                        ) : (
                                          <>
                                            <div className="form-check form-switch">
                                              <input
                                                disabled
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="roti_check"
                                                checked={item.want_roti}
                                              />
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </li>
                                  </ul>
                                </th>
                                {(menuSummary[0]?.schedule_type == "FMBTHALI" && menuSummary[0]?.is_niyaaz == "1") &&
                                <th scope="row">
                                  <ul className="thali_menu_list">
                                    <li>
                                      <div className="Register_user_input">
                                        {item.want_mithas == "-1" ? (
                                          <>-</>
                                        ) : (
                                          <>
                                            <div className="form-check form-switch">
                                              <input
                                                disabled
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="roti_check"
                                                checked={item.want_mithas}
                                              />
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </li>
                                  </ul>
                                </th>
                               }
                                <th scope="row">
                                  <ul className="thali_menu_list">
                                    <li>
                                      <div className="THALI-REGISTERED-check">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            disabled
                                            value={item.is_active}
                                            checked={item.is_active == 1}
                                            // onChange={(e) =>
                                            //   handleTHALIChange(e, index, item.is_active === true)
                                            // }
                                          />
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </th>
                                {/* <th scope="row">
                                  <ul className="thali_menu_list">
                                    <li>
                                      <div className="dropdown action_btn">
                                        <button
                                          className="btn btn-secondary dropdown-toggle"
                                          type="button"
                                          id="dropdownMenuButton2"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i
                                            className="fa fa-ellipsis-h"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                        <ul
                                          className="dropdown-menu"
                                          aria-labelledby="dropdownMenuButton2"
                                        >
                                          <li
                                            onClick={() =>
                                              Registerviewdata(
                                                item.user_id,
                                                item.schedule_day
                                              )
                                            }
                                          >
                                            <a
                                              className="dropdown-item"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="19"
                                                height="15"
                                                viewBox="0 0 19 15"
                                                fill="none"
                                              >
                                                <path
                                                  d="M17.125 8.65L15.35 6.875L16.075 6.15C16.2135 6.01667 16.3898 5.95 16.6039 5.95C16.818 5.95 16.9917 6.01667 17.125 6.15L17.85 6.875C17.9833 7.01352 18.05 7.18982 18.05 7.4039C18.05 7.61797 17.9833 7.79167 17.85 7.925L17.125 8.65ZM9 15V13.225L14.275 7.95L16.05 9.725L10.775 15H9ZM0.75 9.75C0.335787 9.75 0 9.41421 0 9C0 8.58579 0.335786 8.25 0.75 8.25H6.75C7.16421 8.25 7.5 8.58579 7.5 9C7.5 9.41421 7.16421 9.75 6.75 9.75H0.75ZM0.75 5.625C0.335786 5.625 0 5.28921 0 4.875C0 4.46079 0.335786 4.125 0.75 4.125H11C11.4142 4.125 11.75 4.46079 11.75 4.875C11.75 5.28921 11.4142 5.625 11 5.625H0.75ZM0.75 1.5C0.335786 1.5 0 1.16421 0 0.75C0 0.335786 0.335786 0 0.75 0H11C11.4142 0 11.75 0.335786 11.75 0.75C11.75 1.16421 11.4142 1.5 11 1.5H0.75Z"
                                                  fill="#777391"
                                                />
                                              </svg>{" "}
                                              &nbsp; &nbsp;Edit Details
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                  </ul>
                                </th> */}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  {/* <nav>
                    <ul className="pagination">
                      <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={handleFirstPage}>
                          First
                        </button>
                      </li>

                      <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                          Previous
                        </button>
                      </li>

                      {getPageNumbers().map((pageNumber) => (
                        <li
                          className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}
                          key={pageNumber}
                        >
                          <button className="page-link" onClick={() => handlePageChange(pageNumber)}>
                            {pageNumber}
                          </button>
                        </li>
                      ))}

                      <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link pagebutton" onClick={() => handlePageChange(currentPage + 1)}>
                          Next
                        </button>
                      </li>

                      <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={handleLastPage}>
                          Last
                        </button>
                      </li>
                    </ul>
                  </nav> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        id="edit_register"
      >
        <div className="popup_modal">
          <div className="d-flex justify-content-between align-items-center">
            <div className="Popup_heding">
              <img src={editicon} className="img-firud" />
              &nbsp; &nbsp; &nbsp;&nbsp;
              <div className="edit_div">
                <h1>View User Details</h1>
                <p>
                  To view thaali registered data and
                  add/remove or update portion
                </p>
              </div>
            </div>
            <button onClick={closeModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M6.99967 8.19364L1.82142 13.3719C1.65149 13.5418 1.45257 13.6268 1.22467 13.6268C0.996772 13.6268 0.797855 13.5418 0.627922 13.3719C0.458005 13.202 0.373047 13.0031 0.373047 12.7752C0.373047 12.5473 0.458005 12.3483 0.627922 12.1784L5.8062 7.00016L0.627922 1.82191C0.458005 1.65198 0.373047 1.45306 0.373047 1.22516C0.373047 0.99726 0.458005 0.798344 0.627922 0.62841C0.797855 0.458494 0.996772 0.373535 1.22467 0.373535C1.45257 0.373535 1.65149 0.458494 1.82142 0.62841L6.99967 5.80668L12.1779 0.62841C12.3479 0.458494 12.5468 0.373535 12.7747 0.373535C13.0026 0.373535 13.2015 0.458494 13.3714 0.62841C13.5413 0.798344 13.6263 0.99726 13.6263 1.22516C13.6263 1.45306 13.5413 1.65198 13.3714 1.82191L8.19315 7.00016L13.3714 12.1784C13.5413 12.3483 13.6263 12.5473 13.6263 12.7752C13.6263 13.0031 13.5413 13.202 13.3714 13.3719C13.2015 13.5418 13.0026 13.6268 12.7747 13.6268C12.5468 13.6268 12.3479 13.5418 12.1779 13.3719L6.99967 8.19364Z"
                  fill="#2E294E"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="Registers_tabal">
          {/* <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">
                  {" "}
                  <span className="thali_table">Day & Date</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Portion</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Zone</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Roti</span>
                </th>
                <th scope="col">
                  <span className="thali_table">RIce</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Thali Registerd</span>
                </th>
              </tr>
            </thead>
            <tbody> */}
          <div className="card-flex">
            {ragisterView.map((item, index) => {
              // const date = new Date(item.schedule_day);
              const date = convertDateForIos(item.schedule_day)
              const day = date.toLocaleString("en-US", { weekday: "long" });
              const month = date.toLocaleString("en-US", { month: "long" });
              const numericDate = date.getDate();
              const twoDigitYear = date.getFullYear();
              return (
                <div className="card" key={item.id}>
                  <div className="manu-card">
                    <div className="Calendar_card">
                      {" "}
                      <h6>{day}</h6>
                      <h1>{numericDate}</h1>
                      <p>
                        {month}, {twoDigitYear}
                      </p>
                    </div>
                  </div>
                  <div className="manu-card">
                    {" "}
                    <h5 className="thali-heding">Portion</h5>
                    <ul className="thali_menu_list view_Registeruser">
                      <li className="Form_box">
                        <Select
                          options={portionListData}
                          className="dropdown"
                          placeholder="Select Portion"
                          id="inputrice"
                          styles={colourStyles}
                          onChange={(selectedOption) =>
                            handlezoneIdChange2(index, selectedOption.value)
                          }
                          value={
                            item.portion_type_code
                              ? portionListData.find(
                                  (option) =>
                                    option.value === item.portion_type_code
                                )
                              : null
                          }
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="manu-card">
                    <h5 className="thali-heding">Zone</h5>
                    <ul className="thali_menu_list view_Registeruser">
                      <li className="Form_box">
                        <Select
                          options={zoneListData}
                          className="dropdown"
                          placeholder="Select Zone"
                          id="inputrice"
                          styles={colourStyles}
                          onChange={(selectedOption) =>
                            handlezoneIdChange(index, selectedOption.value)
                          }
                          value={
                            item.pickup_location_code
                              ? zoneListData.find(
                                  (option) =>
                                    option.value === item.pickup_location_code
                                )
                              : null
                          }
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="manu-card">
                    <h5 className="thali-heding">Roti</h5>
                    <ul className="thali_menu_list">
                      <li>
                        {item.allow_skip_roti == "0" ? (
                          <>NA</>
                        ) : (
                          <>
                            <div className="Register_user_input">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id={`roti_check_${index}`}
                                  value={item.want_roti}
                                  checked={item.want_roti == "1"}
                                  onChange={(e) =>
                                    handleRotiChange(
                                      e,
                                      index,
                                      item.want_roti == "1"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </li>
                    </ul>
                  </div>
                  <div className="manu-card">
                    <h5 className="thali-heding">Rice</h5>
                    <ul className="thali_menu_list">
                      <li>
                        {item.allow_skip_rice == "0" ? (
                          <>NA</>
                        ) : (
                          <>
                            <div className="Register_user_input">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id={`rice_check_${index}`}
                                  value={item.want_rice}
                                  checked={item.want_rice == "1"}
                                  onChange={(e) =>
                                    handleRiceChange(
                                      e,
                                      index,
                                      item.want_rice == "1"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </li>
                    </ul>
                  </div>
                  <div className="manu-card">
                    <h5 className="thali-heding">Register Thaali</h5>
                    <ul className="thali_menu_list">
                      <li>
                        <div className="THALI-REGISTERED-check">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`is_active_${index}`}
                              value={item.is_active}
                              checked={item.is_active == true}
                              onChange={(e) =>
                                handleTHALIChange(
                                  e,
                                  index,
                                  item.is_active === true
                                )
                              }
                            />
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
              <div className="manu-card">
                  <h5 className="thali-heding">Comments</h5>
                  <div className="new_thali_menu_list">
                    <div className="New_Form_box">
                      <textarea
                        placeholder="Add your comments here..."
                        className="form-control"
                        id="TestlistNew"
                        rows="4"
                        value={item.source == 0 ? '' : item.source}
                        onChange={(e) =>
                          handleSourceChange(
                            e,
                            index
                          )
                        }
                      ></textarea>
                    </div>
                </div>
              </div>
              </div>
              );
            })}
          </div>
          {/* </tbody>
          </table> */}
        </div>
        <div className="Thali_page_btn_from">
          <button type="button" onClick={closeModal} className="btn thali_btn">
            Cancel
          </button>
          &nbsp; &nbsp; &nbsp;
          <button className="btn thali_btn" onClick={handleSubmituser}>
            Update Details
          </button>
        </div>
      </Modal>
      <div
        className="offcanvas offcanvas-end Dishes_canvas vendor_canvas"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            Register User{" "}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          {/* <img src={img_upload} className="img-firud" onClick={() => ref.current.click()} /> */}
          {/* <button onClick={() => ref.current.click()}>Upload</button> */}

          <div className="Dish_Name_list">
            <form className="row g-3">
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="VendorName" className="form-label">
                    Search ITS Id
                  </label>
                  <Select
                    options={userlistits}
                    onChange={(e) => {
                      setNewDataView(e);
                      setItsId({ label: "", value: "" });
                    }}
                    value={ItsId.label ? ItsId : newuserdataview}
                    placeholder="Search ITS Id"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="VendorName" className="form-label">
                    Search User
                  </label>
                  <Select
                    options={userlistall}
                    onChange={(e) => {
                      setUserDataView(e);
                      setUserName({ label: "", value: "" });
                    }}
                    value={userName.label ? userName : userdataview}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="ItsId" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    value={firstname}
                    placeholder="Enter First Name "
                    className="form-control"
                    id="ContactPhone"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="Form_box">
                  <label for="LastName" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    value={lastname}
                    placeholder="Last Name"
                    className="form-control"
                    id="ContactPhone"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="Form_box">
                  <label for="Standard" className="form-label">
                    Want Roti
                  </label>
                  <ul className="thali_menu_list">
                    <div>
                      <div className="Register_user_input">
                        <div className="onoffswitch">
                          <input
                            type="checkbox"
                            name="onoffswitch"
                            className="onoffswitch-checkbox "
                            id="myonoffswitch"
                            checked={roticheck === "1"}
                            onChange={handleToggleChange}
                          />
                          <label
                            className="onoffswitch-label"
                            for="myonoffswitch"
                          >
                            <span className="onoffswitch-inner"></span>
                            <span className="onoffswitch-switch"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="Form_box">
                  <label for="Standard" className="form-label">
                    Want Rice
                  </label>
                  <ul className="thali_menu_list">
                    <div>
                      <div className="Register_user_input">
                        <div className="onoffswitch">
                          <input
                            type="checkbox"
                            name="onoffswitch"
                            className="onoffswitch-checkbox"
                            id="rice_check_Skip"
                            checked={ricecheck == "1"}
                            onChange={handleToggleChangerice}
                          />
                          <label
                            className="onoffswitch-label"
                            for="rice_check_Skip"
                          >
                            <span className="onoffswitch-inner"></span>
                            <span className="onoffswitch-switch"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>

              <div className="col-md-4">
                <div className="Form_box THALI-REGISTERED-check">
                  <label for="ItsId" className="form-label">
                    Register Thali
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="s_active"
                    checked={RegiThali == "1"}
                    onChange={handleToggleRegisterThali}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="VendorName" className="form-label">
                    Zone
                  </label>
                  <Select
                    options={zoneListDataall}
                    className="dropdown"
                    placeholder="Select Zone"
                    id="inputrice"
                    styles={colourStyles}
                    value={addzone}
                    onChange={(e) => setAddZone(e)}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="Form_box">
                  <label for="VendorName" className="form-label">
                    Portion List
                  </label>
                  <Select
                    options={portionListDataall}
                    className="dropdown"
                    placeholder="Select Portion List "
                    id="inputrice"
                    styles={colourStyles}
                    value={Portionzone}
                    onChange={(e) => setPortionZone(e)}
                  />
                </div>
              </div>

              <div className="Ingredients_title">
                <h1></h1>
                <div className="Save_btn">
                  <button
                    id="modal_close_btn"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    id="close_btn"
                    onClick={(e) => handleAddUser(e)}
                  >
                    Save User
                  </button>
                </div>
              </div>
              <div className="Fateha_div">
                <div className="row Menu_class">
                  <h1 className="Caterer_heading">Additional Notes</h1>
                  <div className="col-md-12">
                    <div className="Form_box">
                      <label for="inputCaterer" className="form-label">
                        Comments
                      </label>
                      <textarea
                        placeholder="Add your comments here..."
                        className="form-control"
                        id="Testlist"
                        rows="4"
                        onChange={(e) => setSource(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
